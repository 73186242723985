@value unit: var(--ring-unit);

.container {
  display: flex;

  padding-top: calc(unit * 1.2);
  gap: unit;
}

.message {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.icon {
  flex-shrink: 0;

  width: calc(unit * 4.5);
}

.mainAction {
  margin-right: unit;
  padding: 0;
}

.secondaryAction {
  padding: 0;

  color: #737577;
}

.reduceAnimation.entering {
  max-height: 64px;

  opacity: 1;
}

.entering {
  max-height: 0;

  opacity: 0;
}

.entered {
  max-height: 64px;

  transition: all 0.3s ease-in;

  opacity: 1;
}

.exiting {
  max-height: 0;

  transition: all 0.3s ease-in;

  opacity: 0;
}

.exited {
  max-height: 0;

  transition: all 0.3s ease-in;

  opacity: 0;
}

.unmounted {
  max-height: 0;

  transition: all 0.3s ease-in;

  opacity: 0;
}
