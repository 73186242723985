@value unit: var(--ring-unit);

.dockerfileSource,
.dockerfilePath {
  margin-bottom: calc(unit * 1.5);
}

.dockerfileSource,
.dockerfileSource .dockerfileSourceInput {
  min-height: calc(unit * 13);
}

.dockerfileSource {
  max-height: calc(unit * 20);
}

.radio {
  margin-bottom: calc(unit / 2 * 1.5);
  padding-top: 0;
}
