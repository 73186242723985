@value unit: var(--ring-unit);

.detailedNode {
  display: flex;
  align-items: center;
}

.entityPath {
  display: flex;
  align-items: center;

  width: 100%;

  padding: 0;

  letter-spacing: -0.3px;

  font-size: inherit;
}

.entityPath.entityPath {
  margin-bottom: 0;

  font-weight: 400;
}

.entityPathLink {
  color: var(--ring-text-color);

  line-height: inherit;
}

.childrenCount {
  margin-left: unit;

  user-select: none;

  white-space: nowrap;

  color: var(--ring-disabled-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.link {
  max-width: calc(100% - unit * 2.5);

  color: var(--ring-text-color);
}

.buildType.buildType {
  align-items: baseline;

  font-size: var(--ring-font-size);
  font-weight: 700;
  line-height: var(--ring-line-height);
}
