@value unit: var(--ring-unit);

.container {
  max-width: calc(unit * 70.5);
  padding-top: calc(unit * 12.5);
}

.description {
  padding-bottom: calc(unit * 1.5);
}

.vcsList {
  margin-top: calc(unit * 1.5);

  border: 1px solid var(--ring-line-color);
  border-radius: unit;
}

.listDivider {
  position: relative;

  margin-top: calc(unit * 1.5);

  text-align: center;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    height: 1px;

    content: '';

    background-color: var(--ring-line-color);
  }
}

.listDividerText {
  position: relative;

  padding: 0 calc(unit * 2);

  background-color: var(--ring-content-background-color, #fff);
}
