@value unit: var(--ring-unit);

.version {
  position: fixed;
  z-index: var(--ring-fixed-z-index);
  right: 0;
  bottom: 0;

  padding: calc(unit / 2) calc(unit * 3) 6px calc(unit * 1.5);

  background-color: rgba(var(--ring-content-background-components), 0.6);
  backdrop-filter: blur(2px);

  font-size: 10px;
  line-height: var(--ring-line-height-lowest);
}
