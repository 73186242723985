@value font-smaller, ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.systemProblems {
  composes: font-smaller;

  display: flex;

  align-items: baseline;

  white-space: nowrap;
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-error-color);
}

.text {
  composes: ellipsis;
}

.countProblems {
  flex: 0 0 auto;

  margin-left: calc(unit / 4);
}

.showDetails {
  height: auto;
  margin-right: calc(unit * -1);
  padding: 0 unit;

  line-height: inherit;
}
