@value unit: var(--ring-unit);

.page {
  display: flex;
  flex: 1;
  flex-direction: column;

  margin: 0 calc(var(--tc-page-padding-inline) * -1);
  padding: 0 var(--tc-page-padding-inline);
}

.header {
  margin: calc(unit * -2.5) calc(var(--tc-page-padding-inline) * -1) 0;
  padding: calc(unit * 2.5) var(--tc-page-padding-inline);

  border-bottom: 1px solid var(--ring-line-color);

  background-color: var(--ring-content-background-color);
}

.contentContainer {
  flex: 1;

  margin: calc(unit * -2.5) calc(var(--tc-page-padding-inline) * -1) 0;
  padding: calc(unit * 2.5) var(--tc-page-padding-inline);

  background-color: var(--ring-secondary-background-color);
}
