@value titles, measure from '@jetbrains/ring-ui/components/tabs/tabs.css';
@value font-smaller-lower from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.collapsed {
  margin-left: calc(unit * 2);
}

.note {
  composes: font-smaller-lower;

  display: flex;
  align-items: center;

  margin-top: unit;

  padding: unit calc(unit * 2);

  text-align: center;

  color: var(--ring-secondary-color);
  border-top: 1px solid var(--ring-line-color);

  background-color: var(--ring-secondary-background-color);
}

.noteText {
  padding-left: unit;
}

.link {
  display: flex;
  align-items: center;
  flex: 1 0;

  height: calc(unit * 4);
}

.counter {
  composes: font-smaller-lower;

  margin-left: unit;

  text-decoration-line: none;

  color: var(--ring-secondary-color);

  font-weight: normal;
}

/* stylelint-disable selector-max-specificity */
.link,
.link:hover,
.link:focus,
.link:active {
  & .name {
    color: var(--ring-text-color);
  }

  & .icon.icon.mammoth,
  & .counter {
    color: var(--ring-secondary-color);
  }
}
/* stylelint-enable */

.link:hover {
  background-color: var(--ring-selected-background-color);
}

.linkContent {
  composes: font-smaller-lower;

  display: flex;
  align-items: center;
}

.tabs,
.tabs > div:nth-child(2),
.classicTab,
.tab {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tabs .titles:not(.measure) {
  box-sizing: content-box;
  width: 100%;
  margin-inline: calc(var(--tc-page-padding-inline) * -1);
  padding-inline: var(--tc-page-padding-inline);
}
