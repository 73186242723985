@value unit: var(--ring-unit);

.arrow {
  composes: arrow from '../../../../../../common/CollapsibleLine/CollapsibleLine.css';
}

.button {
  position: absolute;
}

.root {
  composes: root from '../../../../../../common/NestedSubprojectsAndBuildTypes/BuildTypeLine/BuildTypeLine.css';

  width: calc(100% + unit * 4);

  min-width: 0;
  margin: 1px calc(unit * -2) calc(unit / 4);
  grid-column: start / end;

  outline: none;
}

.line {
  composes: line from '../../../../../../common/CollapsibleLine/CollapsibleLine.css';

  margin-top: calc(unit / 4);
}

.header {
  display: flex;
  align-items: baseline;

  width: 100%;
  min-height: calc(unit * 3);
  margin: 0;
}

.moreLink {
  display: inline-block;

  padding: unit 0;
}

.title {
  flex: 1;

  min-width: 0;
}

.changes {
  display: inline-flex;

  margin-right: calc(unit * 2);

  font-weight: normal;
}

.runColumn {
  padding-right: 0;
}

.pendingBuilds {
  margin-left: calc(unit / 2);
}

@value showQueuedButtonMaxWidth: calc(unit * 23);

.showQueuedButton {
  max-width: showQueuedButtonMaxWidth;
  padding-left: 0;
}

.showQueuedButtonText {
  max-width: calc(showQueuedButtonMaxWidth - unit * 4);

  text-align: left;
}

.flash.flash,
.flash .showQueuedIcon {
  transition: none;

  color: var(--tc-highlight-color);
}

.hidden {
  display: none;
}

.branch {
  box-sizing: border-box;
  max-width: 100%;
  margin-top: 2px;

  &.withQueuedBuild {
    max-width: calc(100% - showQueuedButtonMaxWidth);
  }
}

.chevron {
  composes: chevron from '../../../../../../common/NestedSubprojectsAndBuildTypes/BuildTypeLine/BuildTypeLine.css';
}

.placeholder {
  grid-column: start / end;

  box-sizing: border-box;
  margin: 0 calc(unit * -2) unit;

  border: 1px solid var(--ring-line-color);
  border-radius: 3px;
}

.placeholderItem {
  display: flex;

  box-sizing: border-box;

  width: 100%;
  height: calc(unit * 8);
  padding-top: unit;
  padding-left: calc(unit * 2);

  color: var(--ring-secondary-color);
}

.oneLine.placeholder {
  margin-bottom: calc(unit * 1.5);
}

.oneLine.placeholderItem {
  height: calc(unit * 4);
  padding-top: 4px;
}

.details {
  display: contents;
}
