@value unit: var(--ring-unit);

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  box-sizing: border-box;
  height: calc(100% - calc(unit * 4));
}

.panel {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 0 calc(unit * 4);

  border-bottom: 1px solid var(--ring-border-disabled-color);
}

.heading {
  display: flex;
  align-items: center;

  width: 100%;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.title {
  font-weight: 600;
}

.actions {
  display: flex;
  gap: calc(unit * 1.5);
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}

.chevron {
  transform: rotate(-90deg);
}

.help {
  margin-right: calc(unit * 2);
  margin-left: calc(unit / 2);
}

.topTabs {
  width: 100%;
}

.tabs {
  .tab + .tab {
    margin-left: calc(unit * 2);
  }

  .tab {
    &:hover {
      color: var(--ring-text-color);
    }
  }

  & > div {
    margin: 0;

    box-shadow: none;

    & > div {
      margin: 0;

      box-shadow: none;
    }
  }
}

.container {
  display: flex;
  overflow: hidden auto;
  flex: 1;
  flex-direction: column;

  box-sizing: border-box;

  height: calc(100% - calc(unit * 4));
  min-height: calc(100% - calc(unit * 4));
}

.pluginWrapper.pluginWrapper {
  display: none;

  width: 100%;
  height: 100%;

  &.selected {
    display: flex;
  }
}

.incompatibilityWarning {
  position: sticky;
  z-index: var(--ring-fixed-z-index);
  top: 0;

  display: flex;
  justify-content: space-between;

  padding: calc(unit * 1.5) calc(unit * 2.5) calc(unit * 1.5) calc(unit * 4);

  background: var(--ring-warning-background-color);
}

.loader {
  padding: unit calc(unit * 4);
}
