@value unit: var(--ring-unit);

.container {
  display: flex;

  padding: calc(unit * 1.5) calc(unit * 3);

  border-radius: var(--ring-border-radius);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
  column-gap: unit;
}

.info {
  background-color: var(--ring-hover-background-color);

  & .icon {
    color: var(--ring-main-color);
  }
}

.warning {
  background-color: var(--ring-warning-background-color);

  & .icon {
    color: var(--ring-icon-warning-color);
  }
}

.exception {
  background-color: var(--ring-removed-background-color);

  & .icon {
    color: var(--ring-icon-error-color);
  }
}
