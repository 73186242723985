@value unit: var(--ring-unit);

.item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;

  max-width: 100%;

  padding: unit calc(unit * 1.5);

  cursor: pointer;
}

.item:hover {
  background-color: var(--ring-hover-background-color);
}

.focused {
  background-color: var(--ring-selected-background-color);
}

.focused:hover {
  background-color: var(--ring-selected-background-color);
}

.item:active {
  background-color: var(--ring-selected-background-color);
}

.icon {
  margin-right: unit;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
}

.summary {
  display: flex;
  justify-content: space-between;

  min-width: fit-content;
}

.vendor {
  margin-left: unit;

  color: var(--ring-secondary-color);
}

.vendor::before {
  content: 'by ';

  color: var(--ring-secondary-color);
}

.verified {
  margin-left: calc(unit / 4);
}

.verified svg {
  vertical-align: -2px !important;
}

.owningProject {
  margin-left: calc(unit / 2);

  color: var(--ring-secondary-color);
}

.owningProject::before {
  content: 'from ';

  color: var(--ring-secondary-color);
}

.description {
  display: -webkit-box;

  overflow: hidden;

  color: var(--ring-secondary-color);

  line-height: calc(unit * 2);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.statistics {
  display: flex;

  min-width: fit-content;
}

.statisticsItem {
  margin-left: calc(unit / 2);

  color: var(--ring-secondary-color);
}

.statisticsValue {
  margin-left: calc(unit / 2);

  color: var(--ring-secondary-color);
}

.deprecated {
  color: var(--ring-secondary-color);
}
