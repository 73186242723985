@value unit: var(--ring-unit);
@value ellipsis from '../../../../ring-globals.css';

.container {
  display: flex;
  flex-direction: column;
}

.detail {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  overflow-wrap: break-word;

  &:not(:last-child) {
    padding-bottom: calc(unit / 4);
  }
}

.assignment {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  display: -webkit-box;
  overflow: hidden;

  padding-bottom: calc(unit / 4);

  line-height: var(--ring-line-height);
}

.full {
  white-space: normal;
}

.scopeList {
  overflow: hidden;
}

.entityPath {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.entityPath .entityPathLink {
  color: var(--ring-link-color);
}

.fullPath {
  white-space: normal;
  word-break: break-all;
}

.showButton {
  padding: 0;

  color: var(--ring-link-color);
}
