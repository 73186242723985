.list {
  display: flex;
  flex-wrap: wrap;

  margin-top: calc(1.25 * var(--ring-unit));
  gap: calc(3 * var(--ring-unit));
}

.title.title {
  margin-top: calc(2 * var(--ring-unit));
}

.pipelineGroup {
  margin-top: calc(1.5 * var(--ring-unit));
}

.pipelineName {
  font-weight: 600;
}

.icon {
  margin-right: var(--ring-unit);
}
