@value unit: var(--ring-unit);

.popupIcon {
  height: calc(unit * 2);
  margin-left: 2px;
  padding: 0;

  vertical-align: -1px;

  line-height: calc(unit * 2);
}

.popupIcon.popupIcon:hover {
  background-color: var(--ring-tag-background-color);
}

.popupIcon.popupIcon.active,
.popupIcon.popupIcon:active {
  background-color: var(--ring-selected-background-color);
}

.active .iconWrapper,
.popupIcon:active .iconWrapper {
  color: var(--ring-main-color);
}
