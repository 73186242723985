@value font-smaller from '../../../../ring-globals.css';
@value border-radius: 3px;
@value unit: var(--ring-unit);

.header {
  position: sticky;
  z-index: 1;
  top: 0;

  display: flex;
  align-items: baseline;

  padding-bottom: unit;

  background-color: var(--ring-content-background-color);

  &::after {
    position: absolute;
    right: 0;
    bottom: -4px;
    left: 0;

    height: 4px;

    content: '';

    background-image: linear-gradient(
      to bottom,
      rgba(var(--ring-content-background-components), 0.7),
      rgba(var(--ring-content-background-components), 0.7)
    );
  }
}

.hasMessages {
  box-shadow: inset 0 -1px 0 0 var(--ring-line-color);
}

.content {
  position: relative;

  display: block;
  overflow: hidden;

  margin-top: calc(unit / 2);

  text-decoration-line: none;

  color: inherit;
}

.warning {
  position: relative;

  overflow: hidden;

  padding-top: calc(unit * 2);

  color: var(--ring-secondary-color);

  font-size: font-smaller;
  line-height: var(--ring-line-height-lowest);

  &::before {
    position: absolute;
    top: -6px;
    right: -6px;
    left: -6px;

    content: '';

    border-top: 7px dashed var(--ring-line-color);
  }
}

.footer {
  margin-top: calc(unit * 2);
}

.warning + .footer {
  margin-top: unit;
}

.message {
  padding: 0;
}

.heading {
  margin-right: unit;
}

.title {
  font-weight: bold;
}

.diff {
  composes: font-smaller;

  color: var(--ring-secondary-color);
}

.diffLink {
  margin-right: calc(unit / 2);
}

.actions {
  margin-left: auto;
}

.actionButton {
  height: var(--ring-line-height);
  margin: 0;

  margin-left: calc(unit * 1.5);
  padding: 0;

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height);
}

.showFullLogLink {
  &:hover .innerLink,
  &:focus .innerLink {
    border-bottom-width: 0;
  }
}

.button {
  margin-right: unit;
}

.loader {
  margin-left: unit;
}
