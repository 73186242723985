@value unit: var(--ring-unit);

.secretActions {
  min-width: calc(unit * 8);
}

.secret {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  margin-bottom: unit;

  line-height: calc(unit * 3);

  &:hover .secretActions > * {
    opacity: 1;
  }
}

.restoreButton,
.removeButton,
.editButton {
  height: auto;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.secretInputWrapper {
  position: relative;

  display: flex;
  align-items: baseline;
  flex: 1;
  gap: unit;
}

.popupError {
  width: calc(100% - 60px);
}

.secretName,
.secretValue {
  display: -webkit-box;
  overflow: hidden;

  flex: 1;

  max-height: calc(unit * 12.5);

  text-overflow: ellipsis;

  line-break: anywhere;
  -webkit-line-clamp: 4;

  line-clamp: 4;

  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
}

.secretInputWrapperDeleted {
  color: var(--ring-secondary-color);
  -webkit-text-fill-color: var(--ring-secondary-color);
}

.secretInputWrapperDeleted .secretName {
  text-decoration-line: line-through;

  background-color: transparent;
}

.secretValue {
  display: flex;

  line-height: 16px;
  gap: 0 1px;
}

.inputSecretName {
  text-overflow: ellipsis;
}

.inputSecretName:disabled {
  text-decoration-line: line-through;

  background-color: transparent;
}

.secret:not(:focus-within) .inputSecretName {
  border-color: transparent;
  background-color: transparent;
}

.secret:focus-within .inputSecretName {
  background-color: var(--ring-content-background-color);
}

.secret:focus-within .secretActions > * {
  visibility: hidden;
}
