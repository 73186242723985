@value unit: var(--ring-unit);

.licenses {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 2);
}

.link {
  padding: 0;

  font-size: var(--ring-font-size);
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit / 2);

  & .title {
    margin: 0;
    padding: 0;

    font-size: 16px;
  }
}

.links {
  display: flex;
  column-gap: calc(unit * 1.5);
}
