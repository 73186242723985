@value unit: var(--ring-unit);

.content {
  padding-bottom: calc(2 * unit);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: calc(1.5 * unit);

  margin-top: calc(3 * unit);
}

.dialog {
  width: 460px;
  min-width: 460px;
}

.innerContent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.conditionalSelect {
  margin-top: unit;
}

.valueInput {
  margin-top: unit;
}

.hint {
  margin-top: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.scrollableContent.scrollableContent {
  padding-top: unit;
  padding-bottom: calc(2 * unit);
}

.parameterNameInput {
  margin-top: calc(2 * unit);
}

.sign {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
