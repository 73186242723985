@value unit: var(--ring-unit);
@value font-smaller-lower from '../../../../../../ring-globals.css';

.wrapper {
  display: flex;
  flex: 1 1 0;
}

.search {
  width: auto;
  margin-right: calc(unit * 4);
}

.nothingFound {
  composes: font-smaller-lower;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  flex-grow: 1;

  margin: calc(unit * 2) 0 0 calc(unit * 4);

  color: var(--ring-secondary-color);
}

.tree {
  flex: 1 1 auto;
}
