@value ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  z-index: 3;

  display: grid;
  overflow: hidden;

  box-sizing: border-box;

  margin: calc(unit * -2) calc(var(--tc-page-padding-inline) * -1) 0;
  padding: calc(unit * 2) var(--tc-page-padding-inline);

  border-bottom: 1px solid var(--ring-line-color);

  background-color: var(--ring-content-background-color);

  grid-gap: calc(unit * 1.5);
  grid-template-columns: max-content 1fr;
  grid-template-rows: auto;
}

.heading {
  grid-column: 1;
  grid-row: 1;

  overflow: hidden;
  align-content: center;
}

.title {
  composes: ellipsis;
}

.title.title {
  margin: 0;
  padding: 0;
}

.content {
  grid-column: span 2;
  grid-row: 2;

  display: flex;

  column-gap: calc(unit / 2);

  & > div {
    max-width: calc(unit * 50);
  }
}

.actions {
  grid-column: 2;
  grid-row: 1;

  display: flex;
  justify-content: end;
}
