@value unit: var(--ring-unit);

.link {
  display: flex;
  align-items: center;
  flex-direction: column;

  border-radius: unit;

  font-size: 10px;
  line-height: 14px;
}

.withCaption {
  width: calc(unit * 8);
  padding-block: calc(unit / 2);
}

.withCaption.withCaption:focus-visible {
  box-shadow: 0 0 0 2px var(--ring-border-hover-color) inset;
}

.iconWrapper {
  position: relative;
}

.icon {
  display: block;

  margin: 2px;
  padding: unit;

  color: var(--ring-active-text-color);
  border-radius: 6px;
}

.icon.icon svg {
  vertical-align: -4px;
}

.link.link:hover {
  text-decoration: none;

  color: var(--ring-text-color);
}

.link.active {
  color: var(--ring-text-color);
}

.active .icon,
.link:hover .icon {
  background: var(--ring-tag-hover-background-color);
}

.badge {
  position: absolute;
  top: 0;
  right: 0;

  padding: 1px 3.5px;

  color: var(--ring-white-text-color);
  border-radius: 7px;

  background-color: rgba(var(--ring-border-selected-disabled-components), 0.6);
  backdrop-filter: blur(2px);

  font-size: 10px;
  line-height: 12px;
}
