@value unit: var(--ring-unit);

.addParameterButton {
  height: calc(unit * 2.5);

  font-size: var(--ring-button-font-size);

  line-height: calc(unit * 2.5);
}

.parameterForm {
  padding-bottom: calc(unit * 1.5);
}

.helpText {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}
