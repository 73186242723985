@value unit from '../../globals.css';

.title {
  font-weight: bold;
}

.content {
  max-width: 100%;
  padding: calc(unit * 3) 0;

  & .heading {
    margin-bottom: calc(unit * 2);

    line-height: 1em;
  }

  & .icon {
    margin-right: calc(unit / 2);
  }
}
