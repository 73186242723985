@value unit: var(--ring-unit);

.dropdown {
  margin-left: unit;

  white-space: nowrap;

  & button {
    padding-left: 0;
  }
}

.anchor {
  color: var(--ring-secondary-color);
}

.popup {
  max-width: 600px;
  padding: unit calc(2 * unit) calc(2 * unit);

  color: inherit;
}
