@value unit: var(--ring-unit);

.container {
  display: flex;

  box-sizing: border-box;

  padding: calc(unit) calc(unit) calc(unit) calc(unit * 2);

  color: var(--ring-text-color);

  border-radius: unit;

  background: var(--ring-secondary-background-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.text {
  padding: calc(unit * 0.5) 0;
}
