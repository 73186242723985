@value unit: var(--ring-unit);

.name {
  margin-bottom: calc(unit * 0.25);

  line-height: var(--ring-line-height);
}

.newFailure {
  font-weight: bold;
}

.status {
  display: flex;
  align-items: baseline;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.icon {
  margin-right: calc(unit / 4);

  color: var(--ring-icon-error-color);
}

.text {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  display: -webkit-box;
  overflow: hidden;
  overflow-wrap: break-word;

  &.compact {
    -webkit-line-clamp: 6;
  }
}

.statusText {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  display: -webkit-box;
  overflow: hidden;
}

.full {
  -webkit-box-orient: initial;
}
