@value container from '../../common/Plugin/Plugin.css';
@value unit: var(--ring-unit);

.mainPanel {
  position: relative;
  z-index: 0; /* creates an isolated stacking context */

  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.withBottomPadding {
  padding-bottom: calc(unit * 4);
}

.centered {
  box-sizing: border-box;
  width: calc(unit * 110.5);
  margin: auto;
}

.content {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  padding: calc(unit * 2) var(--tc-page-padding-inline) 0;
}

.router {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}

.beforeContent .container {
  display: block;
}
