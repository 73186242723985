@value unit: var(--ring-unit);

.dialog {
  width: calc(
    70vw - unit * 6 * 2
  ); /* TODO: change to unit * 4 * 2 after fixing close button position */

  height: calc(70vh - unit * 2 * 2);
}

.overlay {
  padding: 0;
}

.wrapper {
  display: flex;

  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;

  height: 100%;
  padding: calc(unit * 4);
}

.header {
  padding-bottom: calc(unit * 4);
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;

  margin: unit calc(unit * -4) 0;
  padding: calc(unit * 2) calc(unit * 4) 0;

  box-shadow: 0 -1px var(--ring-line-color);
}

.noFiles {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  color: var(--ring-secondary-color);
}
