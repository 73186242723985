@value scrollableWrapper from '../../../../node_modules/@jetbrains/ring-ui/components/island/island.css';
@value font, font-smaller, ellipsis from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.stacktraceHeading.stacktraceHeading {
  margin: 0;
  margin-top: calc(unit * 1.5);

  color: var(--ring-main-color);

  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}

.chevronIcon {
  margin-right: 2px;
}

.stacktrace {
  composes: font-smaller;

  margin: 0;
  margin-top: 6px;

  font-family: var(--ring-font-family-monospace);
}

.solutionLink {
  height: auto;
  margin: 0 calc(unit * -2) 0 auto;

  line-height: inherit;
}

.dialog {
  padding: calc(unit * 4);
}

.dialogContent {
  width: auto;
  min-width: calc(unit * 60);
  max-width: calc(unit * 150);

  max-height: 600px;
}

.content {
  & .scrollableWrapper {
    padding: calc(unit * 4);
  }
}

.heading {
  margin: 0;

  margin-bottom: calc(unit * 1.5);
  padding: 0;
}

.item {
  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);
}

.item:not(:first-child) {
  padding-top: calc(unit * 0.5);
}

.item:not(:last-child) {
  padding-bottom: unit;
}

.itemHeadingWrapper {
  display: flex;
  align-items: baseline;
}

.description {
  composes: font;
}
