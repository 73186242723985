.added {
  fill: var(--tc-success-progress-color);
}

.modified {
  fill: var(--ring-main-color);
}

.checkbox {
  display: inline-flex;
  align-items: center;
  align-self: stretch;

  flex-basis: auto;
  flex-shrink: 0;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  align-self: stretch;

  white-space: pre;
}
