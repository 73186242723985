@value unit: var(--ring-unit);
@value font from '../../../../../ring-globals.css';
@value font-smaller-lower from '../../../../../ring-globals.css';

.row {
  composes: font;

  display: flex;
  overflow: hidden;

  padding: 0 var(--tc-page-padding-inline);

  line-height: calc(unit * 3);
}

.content {
  display: flex;
  overflow: hidden;
  flex-grow: 1;

  padding: unit 0;

  border-bottom: 1px solid var(--ring-line-color);
}

.columnName {
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;

  margin-right: calc(unit * 2);
}

.column {
  overflow: hidden;

  white-space: pre-line;
  word-break: break-all;

  line-height: var(--ring-line-height-lower);
  line-break: anywhere;
}

.columnValue {
  flex-grow: 1;
  flex-shrink: 1;
}
