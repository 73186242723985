@value font-smaller from '../../../ring-globals.css';

.compatibleAgents {
  composes: font-smaller;
}

.anchor {
  color: var(--ring-text-color);
}

.secondary {
  color: var(--ring-secondary-color);
}
