@value unit: var(--ring-unit);

.container {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.optimizations {
  display: flex;
  flex-direction: column;
  gap: calc(unit * 2);
}

.optimization {
  display: flex;
  justify-content: space-between;
}

.link {
  color: #8496ad;
}

.tag {
  padding: 0 calc(unit / 2);

  color: var(--ring-secondary-color);
  border-radius: calc(unit / 2);
  background-color: var(--ring-tag-background-color);
}

.nameTag {
  display: flex;
  gap: calc(unit * 0.75);

  color: var(--ring-secondary-color);
}
