@value ring: '../node_modules/@jetbrains/ring-ui/components/global/global.css';
@value unit: var(--ring-unit);
@value text-color: var(--ring-text-color);
@value link-color: var(--ring-link-color  );
@value link-hover-color: var(--ring-link-hover-color  );
@value secondary-color: var(--ring-secondary-color);
@value max-content-width: 1176px;

.guidesPluginContainer {
  flex-direction: column;
  flex-wrap: nowrap;
}
