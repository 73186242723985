@value font-smaller-lower from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  margin-bottom: calc(unit * 3);
}

.header .heading {
  overflow: hidden;

  margin: 0;
  margin-bottom: calc(unit * 2);

  word-break: break-word;
}

.headingTestName {
  margin-right: 30px;
}

.copyTestNameIconWrapper {
  margin-left: -30px;
}

.copyTestNameIcon {
  height: 28px;

  opacity: 0;

  &:focus {
    opacity: 1;
  }
}

.heading:hover .copyTestNameIcon {
  opacity: 1;
}

.subHeading {
  composes: font-smaller-lower;

  color: var(--ring-secondary-color);

  font-weight: normal;
}

.branchFilter,
.investigationMuteButtons {
  margin-right: unit;
}
