@value unit: var(--ring-unit);

.heading.heading {
  margin: 0;
}

.title.error {
  color: var(--ring-error-color);
}

.title.success {
  color: var(--ring-success-color);
}

.star {
  height: calc(unit * 3);
  margin-top: -3px;
  margin-left: 6px;

  vertical-align: 3px;
}

.top {
  display: flex;
}

.topSequenceLoader {
  display: flex;

  margin-left: auto;

  gap: unit;
}

.topSequenceLoader > :empty {
  margin-left: calc(unit * -1);
}

.description {
  flex: 1 0 auto;

  margin-top: calc(unit / 2);
}

.headerInfo {
  grid-area: header;

  margin-bottom: calc(unit * 1.5);

  padding-right: calc(unit * 2);
}

.statusChangeComment {
  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.main {
  display: grid;
  grid-template-areas: 'header header' 'badges chart';
  grid-template-columns: 1fr auto;
}

.leftColumn {
  grid-area: badges;

  overflow: hidden;

  margin-bottom: calc(unit * 2);
  margin-left: calc(var(--tc-page-padding-inline) * -1);
  padding: 0 calc(unit * 2) 0 var(--tc-page-padding-inline);
}

.rightColumn {
  grid-area: chart;

  padding-top: calc(unit / 2);

  line-height: 0;
}

.queued {
  font-weight: normal;
}

.timeBadge.timeBadge {
  min-width: 250px;
}

.agentBadge.agentBadge {
  overflow: hidden;
  flex-shrink: 2;

  min-width: min-content;
  max-width: 240px;
}
