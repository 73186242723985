@value unit: var(--ring-unit);

.header {
  margin-bottom: calc(unit * 4);
}

.header .headingWrapper {
  display: flex;
  align-items: baseline;

  margin-bottom: calc(unit * 4);
}

.header .heading {
  overflow: hidden;

  margin: 0;

  word-break: break-word;
}

.title {
  display: inline-block;
}

.avatar {
  margin-right: unit;
}

.table {
  margin: calc(unit * -1.5) calc(var(--tc-page-padding-inline) * -1) 0;

  border-spacing: var(--tc-page-padding-inline) calc(unit * 1.5);

  border-collapse: separate;

  & th {
    white-space: nowrap;
  }

  & th,
  & td {
    padding: 0;

    text-align: start;
    vertical-align: baseline;
  }
}

.comment {
  overflow-wrap: break-word;

  overflow: hidden;
}

.editCommentButton {
  margin-top: unit;
  padding: 0;
}

.revision {
  line-height: 24px;
}

.revision .revisionLink {
  font: inherit;
}

.uncommitedChangeLabel {
  margin-left: unit;
}
