@value font-smaller-lower from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  display: flex;
  justify-content: space-between;

  padding: calc(unit * 1.5) calc(unit * 0.5) calc(unit * 1.5) calc(unit * 2);
}

.searchWrapper {
  display: flex;
  align-items: baseline;

  width: 100%;
}

.search {
  width: 100%;
}

.collapseIconButtonWrapper {
  margin: 0 unit;
}

.header.collapsed {
  align-items: end;
  flex-direction: column;
  justify-content: start;

  height: 100%;
  padding: 0;

  cursor: pointer;

  &:hover {
    background-color: var(--ring-hover-background-color);
  }

  & .collapseIconButtonWrapper {
    padding: 14px 0 2px;
  }

  & .search {
    overflow: hidden;

    width: 0;
    height: 0;
  }
}

.collapseIcon {
  width: calc(unit * 2);

  color: var(--ring-icon-secondary-color);
}

.collapseTextWrapper {
  padding: unit;

  transform: rotate(180deg);
}

.collapseText {
  composes: font-smaller-lower;

  white-space: nowrap;

  color: var(--ring-secondary-color);
  writing-mode: vertical-rl;
}

.headerTitelRow {
  display: flex;
  justify-content: space-between;
}

.sidebarHeader {
  display: flex;

  padding: calc(unit * 1.5) calc(unit * 0.5) 0 calc(unit * 2);

  font-size: var(--ring-font-size-larger);
}

.headerTitelCollapsed {
  display: none;
}

.headerButton {
  padding: calc(unit * 1.5) calc(unit * 1.5) 0 calc(unit);
}

.headerButtonCollapsed {
  padding: calc(unit * 1.5) calc(unit * 1.5) calc(unit) calc(unit);

  border-bottom: 1px solid var(--ring-line-color);
}
