@value unit: var(--ring-unit);

.cell {
  display: flex;

  height: calc(unit * 3);
  padding: calc(unit * 1.5) 0;
}

.startDate {
  justify-content: end;
}

.duration {
  text-align: end;
}

.status,
.number {
  display: block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.estimate {
  color: var(--ring-secondary-color);
}

.branchLabel {
  font-weight: bold;
}
