@value unit: var(--ring-unit);

.container {
  display: flex;
}

.slider {
  margin-right: calc(unit * 1.5);
  margin-bottom: calc(unit / 2);
}

.hint {
  padding-top: calc(unit * 0.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: 400;
  line-height: var(--ring-line-height-lowest);

  &.top {
    padding-bottom: calc(unit * 1.5);
  }
}

.parallelismCount {
  font-weight: 600;
}

.toggle.toggle {
  cursor: auto;
  pointer-events: auto;
}
