@value unit: var(--ring-unit);

.success {
  color: var(--ring-success-color);
}

.failure {
  color: var(--ring-error-color);
}

.queued {
  color: var(--ring-secondary-color);
}

.tooltipText {
  display: block;

  font-size: var(--ring-font-size-smaller);
}

.popupTooltip {
  padding: calc(unit / 2) unit;
}

.canceled {
  color: var(--ring-secondary-color);
}

.warning {
  color: var(--ring-warning-color);
}

.icon {
  margin-right: calc(unit / 2);
}

.waitReason {
  position: absolute;
  right: unit;
  bottom: 6px;
}
