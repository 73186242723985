@value unit: var(--ring-unit);

.warningIcon.warningIcon {
  margin-right: unit;

  color: var(--ring-icon-warning-color);
}

.rollbackPopup {
  padding: unit calc(unit * 2) calc(unit * 2);
}
