@value unit: var(--ring-unit);

.container {
  margin-block: calc(unit * 1.5);
}

.logo.logo {
  padding-block: 0;

  vertical-align: -2px;
}
