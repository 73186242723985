@value unit: var(--ring-unit);

.button {
  &::before {
    position: absolute;
    top: 0;
    right: calc(var(--tc-page-padding-inline) * -1);
    bottom: 1px;
    left: calc(var(--tc-page-padding-inline) * -1);

    content: '';
  }

  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.open {
  &:not(.groupedTest)::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: calc(var(--tc-page-padding-inline) * -1);
    bottom: 0;
    left: calc(var(--tc-page-padding-inline) * -1);

    content: '';
    pointer-events: none;

    box-shadow:
      0 0 0 1px var(--ring-line-color),
      0 2px 8px var(--ring-popup-shadow-color);

    grid-column: start / end;
    grid-row: var(--row) / span 2;
  }
}

.heading {
  cursor: default;

  &.expandable {
    cursor: pointer;
  }
}

.item,
.heading {
  display: contents;
}

.button:focus {
  outline: none;

  &::before {
    box-shadow: inset 2px 0 var(--ring-main-color);
  }
}

.detailsWrapper {
  grid-column: start / end;
}

.arrow {
  position: absolute;

  grid-column: -2;
  grid-row: var(--row) / span 1;

  top: calc(var(--absolute-child-top) + 8px);
  right: calc(unit * -4 + 10px);
  left: auto;

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: var(--ring-icon-secondary-color);

  line-height: normal;
}

.outerContainer {
  position: relative;

  overflow: hidden;

  height: calc(unit * 11);
  margin: 0 calc(var(--tc-page-padding-inline) * -1);
}

.innerContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  padding: 0 var(--tc-page-padding-inline);
}

.groupedTestArrow {
  position: absolute;

  grid-column: 1;
  grid-row: var(--row) / span 1;

  top: calc(var(--absolute-child-top) + 8px);
  right: auto;
  left: calc(unit * -4 + 10px);

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: var(--ring-icon-secondary-color);

  line-height: normal;
}

.detailsShift {
  padding-left: 22px;
}

.open .arrow,
.open .groupedTestArrow {
  opacity: 1;
}

.item:hover .arrow,
.button:focus ~ .arrow,
.item:hover .groupedTestArrow,
.button:focus ~ .groupedTestArrow {
  transition: none;

  opacity: 1;
}

.button:active ~ .arrow,
.button:active ~ .groupedTestArrow {
  transition: none;

  opacity: 1;
  color: var(--ring-main-color);
}

.jobDetailsSidebarTarget {
  /* stylelint-disable-next-line selector-max-specificity */
  &.open:not(.groupedTest)::before {
    left: calc(unit * -2.5);
  }

  & .button::before {
    left: calc(unit * -2.5);
  }
}

.closed:hover .button,
.closed .button:focus {
  /* stylelint-disable-next-line selector-max-specificity */
  &::before {
    background-color: var(--ring-hover-background-color);
  }
}
