@value unit: var(--ring-unit);

.grid {
  position: relative;

  overflow: hidden;

  margin-left: calc(var(--tc-page-padding-inline) * -1);
  padding-left: var(--tc-page-padding-inline);
}

.underlined {
  border-bottom: 1px solid var(--ring-line-color);
}
