@value unit: var(--ring-unit);

.row {
  display: flex;
  align-items: baseline;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  justify-content: flex-end;

  width: 100%;

  & > * {
    box-sizing: border-box;
    padding: 0 unit;
  }

  & > :first-child {
    padding-left: 0;
  }
}

.line {
  position: relative;

  display: flex;

  align-items: baseline;

  margin: calc(unit * 3.5) calc(var(--tc-page-padding-inline) * -1) 0;

  padding: calc(unit / 2) var(--tc-page-padding-inline);

  outline: none;

  line-height: calc(unit * 3);

  &:focus {
    background-color: var(--ring-hover-background-color);
    box-shadow: inset 2px 0 var(--ring-main-color);
  }
}

.arrow {
  position: absolute;
  top: unit;
  left: unit;

  padding: 0;

  transition: color var(--ring-fast-ease);

  color: var(--ring-icon-secondary-color);

  line-height: normal;

  .line:hover &,
  .line:focus & {
    transition: none;

    color: var(--ring-icon-hover-color);
  }

  .line:active & {
    transition: none;

    color: var(--ring-main-color);
  }
}
