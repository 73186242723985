@value unit: var(--ring-unit);

.progressBar {
  position: absolute;
  right: 10px;
  bottom: 2px;
  left: 10px;

  height: 2px;

  background-color: var(--ring-icon-color);
}

.heading {
  display: block;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lowest);
}

.builds {
  margin-top: unit;
}
