@value unit: var(--ring-unit);

.header {
  display: grid;

  padding-bottom: unit;

  grid-gap: calc(unit * 1.5);
  grid-template-columns: max-content 1fr;
  grid-template-areas:
    'heading actions'
    'subheading subheading';
}

.heading {
  grid-area: heading;

  display: flex;
  align-items: flex-end;
  column-gap: unit;
}

.reusedLabel {
  color: var(--ring-secondary-color);
}

.actions {
  grid-area: actions;

  display: flex;
  justify-content: flex-end;
}

.subheading {
  display: flex;
  flex-direction: column;
  gap: calc(unit * 1.5);

  grid-area: subheading;
}

.badges {
  display: flex;
  gap: calc(unit / 2);

  margin-bottom: calc(unit * 1.5);
  grid-area: badges;

  & > div {
    max-width: calc(unit * 50);
  }
}

.actionButton {
  margin-right: calc(unit * 1.5);
  padding: 0;

  &:last-child {
    margin-right: 0;
  }
}

.navigation.navigation {
  padding: 0;
}
