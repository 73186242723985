@value unit: var(--ring-unit);

.changed {
  background-color: var(--ring-secondary-background-color);
  box-shadow: inset 3px 0 var(--ring-main-color);
}

.row {
  margin: 0 calc(var(--tc-page-padding-inline) * -1);

  &:hover {
    background-color: var(--ring-hover-background-color);

    & .changed {
      background-color: var(--ring-hover-background-color);
    }
  }
}

.col {
  padding: 0;
}

.name {
  padding: 0 var(--tc-page-padding-inline);

  overflow-wrap: break-word;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.values {
  margin: 0;
  padding: 1px calc(var(--tc-page-padding-inline) - unit) 3px;
}

.value {
  margin-top: 0;
  margin-bottom: 0;

  overflow-wrap: break-word;
}

.empty {
  color: var(--ring-secondary-color);
}
