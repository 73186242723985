@value unit: var(--ring-unit);

.description {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.container {
  display: flex;
}
