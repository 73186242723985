@value unit: var(--ring-unit);

.message {
  display: flex;

  width: 100%;
  column-gap: unit;

  & .title {
    margin-bottom: calc(unit / 2);

    font-size: var(--ring-font-size-smaller);
    line-height: var(--ring-line-height-lowest);
  }
}

.button {
  white-space: nowrap;
}

.enterKey {
  height: calc(unit * 2);
  margin-left: auto;
  padding: 0;

  line-height: var(--ring-line-height-lowest);
}

.getLicense {
  margin-left: auto;
}

.readMore {
  height: var(--ring-line-height-lowest);
  padding: 0;

  line-height: var(--ring-line-height-lowest);
}
