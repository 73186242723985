@value unit: var(--ring-unit);

.button {
  margin-left: unit;
}

.badges {
  & > div {
    max-width: calc(unit * 50);
  }
}

.stopButton :global(.secondary) {
  fill: var(--ring-removed-subtle-background-color);
}

.runTimeBadge.runTimeBadge {
  min-width: calc(unit * 44);
}
