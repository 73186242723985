/* ensure correct styles order */
@import '@jetbrains/ring-ui/components/popup/popup.css';

.item {
  display: flex;

  overflow: hidden;

  white-space: nowrap;
}

.contentWrapper {
  overflow: hidden;
  flex: 1 1 auto;
}

.expandHandle {
  flex: 0 0 auto;
}

.popup {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.nonInteractivePopup {
  z-index: var(--ring-fixed-z-index);

  overflow: hidden;

  pointer-events: none;
}

.interactivePopup {
  z-index: 0;

  overflow: hidden;
}
