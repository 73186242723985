@value unit: var(--ring-unit);

.addLine {
  position: absolute;
  z-index: var(--ring-overlay-z-index);
  top: calc(unit * 4 - 2px);

  height: calc(unit * 3);
  padding: 0 calc(unit / 2);

  line-height: calc(unit * 3 + 1px);
}

.addLine > * {
  position: relative;
}

.addLine::before {
  position: absolute;
  left: 9px;

  width: 4px;
  height: 100%;

  content: '';

  border-radius: 2px;
  background-color: var(--job-tile-selected-border-color);
}

.icon {
  opacity: 0;
}

.selected.selected {
  opacity: 1;
}

.selected .icon,
.addLine:hover .icon {
  opacity: 1;
  color: var(--job-tile-selected-border-color);
}

.disabled {
  pointer-events: none;
}

.disabled.disabled .icon {
  opacity: 0;
}

.icon :global(.secondary) {
  fill: var(--ring-content-background-color);
}

.hintAction {
  margin-left: unit;

  color: var(--ring-secondary-color);
}
