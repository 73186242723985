.agentAuth {
  padding: 10px 2px;

  border-top: 1px solid var(--ring-line-color);
}

.open {
  color: var(--ring-link-color);

  font-size: var(--ring-font-size);

  &:hover {
    text-decoration-line: underline;

    color: var(--ring-link-color);
  }
}
