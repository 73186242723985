@value unit: var(--ring-unit);

.container {
  display: flex;
  flex-direction: column;

  padding: calc(unit * 2) calc(unit * 2) 14px;
}

.label {
  padding-bottom: 2px;

  text-transform: uppercase;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.anchor.anchor {
  margin-right: 7px;

  color: var(--ring-white-text-color);
}
