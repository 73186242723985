@value font-smaller, font  from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.container {
  position: relative;
}

.header {
  display: flex;

  padding-bottom: unit;
}

.tree {
  margin: 0;
  padding: 0;
}

.autoWidth {
  display: inline-block;

  min-width: calc(unit * 40);
}

.toggleHidden {
  display: inline-block;
}

.noteHidden {
  composes: font-smaller;

  line-height: var(--ring-line-height-lower);
}

.noArtifacts {
  composes: font-smaller;

  margin-bottom: unit;

  line-height: var(--ring-line-height-lower);
}

.downloadLink {
  margin-left: auto;

  white-space: nowrap;
}

.artifactsSize {
  composes: font;

  font-weight: 600;
}

.artifactsSize .downloadLink {
  margin-left: unit;
}

.loader {
  padding: 6px 0;
}

.popupHeader {
  & .artifactsSize {
    color: var(--ring-secondary-color);

    font-weight: 400;
  }
}
