@value unit: var(--ring-unit);

.noPipelines {
  padding: unit calc(unit * 1.5) unit calc(unit * 2);
}

.noPipelinesMessage {
  color: var(--ring-secondary-color);
}

.createPipelineLink {
  margin-top: calc(unit * 1.5);
}

.addIcon {
  margin-right: calc(var(--ring-unit) / 2);
}
