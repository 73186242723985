@value blue-background-color: var(--ring-hover-background-color);
@value main-background-color: var(--ring-content-background-color);
@value unit: var(--ring-unit);

.optionsBox {
  display: flex;

  margin: calc(unit / 2) 0;
  padding: 0 calc(unit * 2);
}

.codeWrapper {
  margin-top: 0;
  margin-bottom: 0;

  white-space: pre-wrap;

  & code {
    overflow: visible;

    padding-top: 0;
    padding-bottom: calc(unit / 4);

    border-radius: 0;

    font-size: var(--ring-font-size-smaller);
    line-height: 1.5;
  }
}

.blue {
  & code {
    background-color: blue-background-color;
  }
}

.white {
  & code {
    background-color: main-background-color;
  }
}

.additionalIndent {
  margin-left: calc(unit * 8);
}

.smallIndent {
  margin-left: calc(unit * 4);
}

.error {
  max-width: calc(unit * 100);

  margin: unit;

  color: var(--ring-warning-color);
}

.iconButton {
  color: var(--ring-secondary-color);
}

.iconWrapper {
  margin-right: unit;
}

.codeSection {
  overflow: auto;
}
