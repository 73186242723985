.spinner {
  position: relative;
  z-index: 4; /* should be unique for different composite layer */

  transform-origin: 50% 50%;
  animation: spin 1.8s linear infinite;
}

@keyframes spin {
  from {
    transform: translateX(var(--tc-toggle-link-switcher-offset)) rotate(0);
  }

  to {
    transform: translateX(var(--tc-toggle-link-switcher-offset)) rotate(360deg);
  }
}
