.tooltip {
  z-index: 3;

  max-width: calc(40 * var(--ring-unit));
  padding: calc(1.5 * var(--ring-unit)) calc(1.5 * var(--ring-unit)) calc(2 * var(--ring-unit));
}

.container {
  display: flex;
  flex-direction: row;
  gap: calc(1.5 * var(--ring-unit));
}

.info {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--ring-white-text-color);
}

.description {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.button {
  align-self: flex-start;

  margin-top: calc(2 * var(--ring-unit));

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}
