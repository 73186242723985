@value unit: var(--ring-unit);

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  margin-top: calc(4 * unit);
}
