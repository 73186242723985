@value unit: var(--ring-unit);

.sidebar {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;

  padding: 0 calc(unit * 1.5) calc(unit - 2px) calc(unit * 2);

  letter-spacing: 1px;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.header {
  padding: calc(unit * 1.5) calc(unit * 0.5) calc(unit * 1.5) calc(unit * 2);
}

.searchWrapper {
  flex: 1;

  padding: 0;
}
