@value unit: var(--ring-unit);

.help {
  margin-top: unit;
  margin-bottom: calc(unit * 2);

  color: var(--ring-secondary-color);
}

.icon {
  color: var(--ring-error-color);
}
