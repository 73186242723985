@value unit: var(--ring-unit);

.popup {
  min-width: 302px;
  padding: calc(unit / 2);

  border-radius: calc(unit * 1.5);
}

.dropdown {
  max-width: max-content;
}

.content {
  display: flex;
  flex-direction: column;
  gap: calc(unit * 2);

  padding: calc(unit * 1.5);

  border-radius: unit;
  background: var(--ring-secondary-background-color);

  &:not(:last-child) {
    margin-bottom: calc(unit / 2);
  }
}

.primaryLabel {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.secondaryLabel {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.timeLabel {
  font-weight: 600;
}

.time {
  display: flex;
  gap: calc(unit * 0.75);

  align-items: baseline;

  margin-top: calc(unit / 4);
}

.highlighted {
  color: var(--ring-main-color);

  font-size: var(--ring-font-size-smaller);

  font-weight: 600;
  line-height: var(--ring-line-height-lowest);
}

.duration {
  display: flex;
  gap: calc(unit / 2);
}

.durationValue {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.optimizationContainer {
  display: flex;
  flex-direction: column;
  gap: calc(unit * 0.25);
}

.optimization {
  display: flex;
  justify-content: space-between;
}

.timeBarContainer {
  margin-top: calc(unit / 2);
  margin-bottom: 0;
}

.timeBar {
  background-color: var(--ring-border-hover-color);
}

.heading {
  max-width: 270px;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.header {
  margin-bottom: calc(unit / 2);

  font-weight: 600;
}

.subheader {
  color: var(--ring-disabled-color);
}

.onLabel {
  margin-right: calc(unit * 0.75);
}

.hint {
  display: block;

  max-width: 268px;

  margin-top: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: 400;
  line-height: var(--ring-line-height-lowest);
}
