@value unit: var(--ring-unit);

.container.container {
  min-width: max-content;
}

.status {
  display: flex;

  margin-top: calc(-0.5 * unit);
  padding: calc(unit / 2) unit;

  color: var(--ring-white-text-color);

  border-radius: var(--ring-border-radius);
  column-gap: calc(unit / 2);

  &:hover {
    cursor: default;
  }

  &.running {
    background-color: var(--ring-main-color);

    &:hover {
      background-color: var(--ring-main-hover-color);
    }
  }

  &.failure {
    background-color: var(--ring-icon-error-color);

    &:hover {
      background-color: var(--ring-main-error-hover-color);
    }
  }

  &.success {
    background-color: var(--ring-main-success-color);

    &:hover {
      background-color: var(--ring-main-success-hover-color);
    }
  }

  &.canceled {
    background-color: var(--ring-borders-color);

    &:hover {
      background-color: var(--ring-disabled-color);
    }
  }

  &.queued {
    background-color: var(--ring-main-color);

    &:hover {
      background-color: var(--ring-main-hover-color);
    }
  }
}

.icon svg {
  color: var(--ring-white-text-color);
}

.tooltip {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
