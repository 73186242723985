@value ellipsis from '../../../../ring-globals.css';
@value unit: var(--ring-unit);

.link {
  color: var(--ring-secondary-color);
}

.link.link:hover {
  text-decoration-line: none;
}

.leftIcon {
  margin-right: 2px;

  color: var(--ring-icon-color);
}

.active,
.active.active:hover {
  cursor: default;

  color: var(--ring-text-color);
}
