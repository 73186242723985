@value titles, title, selected from '@jetbrains/ring-ui/components/tabs/tabs.css';
@value unit: var(--ring-unit);
@value popupMinHeight: calc(unit * 48);

.wrapper {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  height: 100%;
}

.tabs {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  height: 100%;
}

.tabs .titles {
  width: 100%;
  margin-bottom: 0;

  & .hideGameTabTitle {
    opacity: 0;

    /* stylelint-disable selector-max-specificity */
    &:hover,
    &:focus,
    &.selected {
      opacity: 1;
    }
    /* stylelint-enable */
  }
}

.tabContainer {
  overflow: hidden;
  flex: 1;
}

.tabContainer .tab {
  display: flex;
  overflow: auto;
  flex-direction: column;

  box-sizing: border-box;

  height: 100%;
}

.logTab {
  overflow: hidden;
  flex: 1;

  background: var(--ring-secondary-background-color);
}

.jobDetailsPopupTarget {
  & .header {
    padding: calc(unit * 2.5) var(--tc-page-padding-inline) 0;
  }

  & .tabs .titles {
    padding: 0 var(--tc-page-padding-inline);
  }

  & .tabContainer .tab {
    padding: calc(unit * 2) var(--tc-page-padding-inline);
  }

  & .logTab {
    margin: calc(unit * -1) calc(var(--tc-page-padding-inline) * -1) calc(unit * -2);
  }
}

.jobDetailsSidebarTarget {
  & .header {
    padding: calc(unit * 2.5) var(--tc-page-padding-inline) 0 calc(unit * 2.5);
  }

  & .tabs .titles {
    padding: 0 var(--tc-page-padding-inline) 0 calc(unit * 2.5);
  }

  & .tabContainer .tab {
    padding: calc(unit * 2) var(--tc-page-padding-inline) calc(unit * 2) calc(unit * 2.5);
  }

  & .logTab {
    margin: 0 calc(var(--tc-page-padding-inline) * -1) calc(unit * -2) calc(unit * -2.5);
  }
}

.jobDetailsPopupTarget,
.jobDetailsSidebarTarget {
  & .tabContainer .gameTab {
    padding: 0;
  }
}
