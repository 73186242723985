@value unit: var(--ring-unit);

.item {
  padding: 0 calc(unit * 1.5) !important;

  font-family: var(--ring-font-family-monospace), serif;
  font-size: var(--ring-font-size-smaller);

  & div {
    align-self: baseline;
    flex-shrink: unset;
  }
}

.itemDescription {
  padding-left: var(--ring-unit);

  text-align: right;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: 400;
  line-height: var(--ring-line-height-lowest);
}

.itemLabel {
  cursor: default;
}

.itemLabel,
.itemDescription {
  max-width: calc(unit * 32);
}

.item,
.itemLabel,
.itemDescription {
  white-space: pre-line;

  font-family: var(--ring-font-family-monospace), serif;
  font-size: var(--ring-font-size-smaller);

  line-break: normal;

  & > * {
    white-space: normal;
    line-break: normal;

    word-break: break-all;
    overflow-wrap: anywhere;
  }
}

.text {
  &::before,
  &::after {
    content: '%';

    color: var(--ring-disabled-color);
  }
}
