.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

h1.disabledTitle {
  color: var(--ring-disabled-color);
}

h1.disconnectedTitle {
  color: var(--ring-error-color);
}

.title.title {
  margin-top: var(--ring-unit);
  margin-bottom: calc(1.5 * var(--ring-unit));
}

.header {
  display: flex;
}

.actions {
  margin-left: auto;
}

.icon {
  display: inline-flex;
  align-items: center;
}

.summary {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) / 2);
}

.row {
  display: flex;
  flex-direction: row;

  line-height: 20px;
}

.rowTitle {
  width: calc(20 * var(--ring-unit));

  font-weight: 600;
}

.copyButton {
  height: var(--ring-line-height);
  margin-left: var(--ring-unit);

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height);
}

.parametersTitle.parametersTitle {
  display: flex;
  align-items: baseline;

  margin-top: calc(2 * var(--ring-unit));
  margin-bottom: calc(1.25 * var(--ring-unit));
  gap: var(--ring-unit);
}

.enableAgentButton {
  margin-right: var(--ring-unit);
}
