@value unit: var(--ring-unit);

.button {
  height: auto;
  padding: 0;

  line-height: inherit;
}

.icon.icon {
  color: var(--ring-icon-warning-color);
}

.inlineIcon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-warning-color);
}

.popup {
  max-width: 400px;
  padding: unit calc(2 * unit);

  text-align: left;
}

.warning {
  margin-right: unit;

  color: var(--ring-warning-color);
}
