@value font-smaller-lower from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.row,
.warningRow {
  line-height: calc(4 * unit);
}

.row {
  display: flex;
  flex-direction: row;
}

.rowTitle {
  width: calc(20 * unit);
}

.editLink {
  margin-left: unit;

  color: var(--ring-secondary-color);
}

.editIcon {
  color: var(--ring-icon-color);
}

.warinigIcon {
  color: var(--ring-icon-warning-color);
}

.runningIcon {
  margin-right: calc(unit / 2);
}

.runningBuildHeading.runningBuildHeading {
  margin-bottom: unit;
  padding: 0;

  font: inherit;
  font-weight: bold;
}

.runningBuildPlaceholder {
  composes: font-smaller-lower;

  align-self: center;

  flex: 1 0;
}

.plugins {
  margin-top: calc(2 * unit);
}

.copyButton {
  height: var(--ring-line-height);
  margin-left: unit;

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height);
}
