@value titles from '@jetbrains/ring-ui/components/tabs/tabs.css';
@value unit: var(--ring-unit);

.tabs {
  composes: tabs from '../OverviewTab/OverviewTab.css';

  box-sizing: border-box;
  width: 100%;
  margin-top: -6px;
  padding-inline: var(--tc-page-padding-inline);
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}

.active .icon {
  color: var(--ring-text-color);
}

.counter {
  margin-left: calc(unit / 2);

  color: var(--ring-secondary-color);
}

.marker {
  margin-left: calc(unit / 2);

  color: var(--ring-main-color);
}

.disabled {
  display: flex;
  align-items: center;

  cursor: default;

  color: var(--ring-disabled-color);

  & .icon,
  & .counter,
  & .marker {
    color: var(--ring-disabled-color);
  }
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.ring-tabs-title) + .disabled {
  margin-left: calc(unit * 3);
}

.tab:hover .icon {
  color: var(--ring-link-hover-color);
}

.warningIcon {
  margin-right: calc(unit / 2);

  color: var(--ring-warning-color);
}
