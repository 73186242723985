@value resetButton, font-smaller-lower, ellipsis from '../../../../ring-globals.css';
@value chevronWidth: 24px;
@value unit: var(--ring-unit);

.button {
  composes: resetButton;

  width: 100%;
}

.projectsTreeItem {
  position: relative;

  display: block;

  overflow: hidden;

  color: var(--ring-text-color);

  border-radius: 0;
  outline: none;

  &&:hover {
    text-decoration-line: none;

    color: var(--ring-text-color);
    background-color: var(--ring-tag-background-color);
  }

  &&.selected {
    background-color: var(--ring-selected-background-color);
  }
}

.checkbox {
  display: flex;
  align-items: baseline;
}

.legacyCheckbox {
  position: relative;
  top: 1px;

  flex-shrink: 0;

  outline: none; /* the whole line is highlighted on focus */
}

.checkboxLabel {
  min-width: 0;
}

.content {
  display: flex;

  align-items: baseline;

  height: calc(3 * unit);

  padding-right: unit;

  line-height: calc(2.5 * unit);

  & > * {
    flex-shrink: 0;
  }
}

.name {
  composes: ellipsis;

  position: relative;
  z-index: 1;

  flex: 1 1 auto;

  min-width: calc(unit * 5);
  height: 100%;
}

.archived .name {
  color: var(--ring-secondary-color);
}

.active {
  background-color: var(--ring-selected-background-color);

  font-weight: bold;
}

.action {
  cursor: pointer;
}

.projectsTreeItem:focus,
.projectsTreeItemParent:focus .selected {
  box-shadow: inset 2px 0 var(--ring-main-color);
}

.chevronButton {
  position: absolute;
  z-index: 1;
  top: 3px;

  padding: 0 calc(unit / 2);

  & .chevronIcon {
    transition: none;

    color: var(--ring-icon-secondary-color);
  }

  &:hover,
  &.hovered {
    & .chevronIcon {
      color: var(--ring-icon-hover-color);
    }
  }
}

.fakeChevronButton {
  flex: 0 0 chevronWidth;

  width: chevronWidth;
  height: calc(2 * unit);
}

.title {
  display: flex;

  margin: calc(2 * unit - 3px) 0 7px;

  padding: 0 12px 0 6px;

  letter-spacing: 2px;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size-smaller);

  line-height: calc(3 * unit);
}

.row:first-child .title {
  margin-top: 0;
}

.gridcell {
  position: relative;
}

.server {
  composes: ellipsis;

  padding: calc(2 * unit) calc(unit + 2px) 3px calc(2 * unit);
}

.serverTitle {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size);
}

.serverDetails {
  font-size: var(--ring-font-size-smaller);

  line-height: calc(unit * 3);
}

.dropdown {
  display: block;
}

.tooltip {
  composes: tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip.css';
  composes: long from '@jetbrains/ring-ui/components/tooltip/tooltip.css';

  z-index: var(--ring-alert-z-index);

  &.inPopup {
    z-index: 1000;
  }
}

.highlighting {
  color: var(--tc-highlight-color);
}

.counter {
  white-space: nowrap;
}

.counterIcon {
  margin-right: 2px;
  margin-left: 6px;

  color: var(--ring-icon-color);
}

.warningIcon {
  margin-left: calc(unit / 2);

  color: var(--ring-icon-error-color);
}

.counterText {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
}

.pendingCounter {
  margin-left: 6px;

  color: var(--ring-main-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
}

.newFailedTestsCounter {
  margin-left: 6px;

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: bold;
}

.starIcon {
  &.starIcon {
    transition: none;
  }

  &.hovered {
    color: var(--ring-icon-hover-color);
  }
}

.note {
  composes: font-smaller-lower;

  margin-right: 2px;
  margin-left: 6px;

  color: var(--ring-secondary-color);

  font-weight: normal;
}

.linkIcon {
  margin-right: 6px;

  color: var(--ring-text-color);

  line-height: normal;
}

.newWindowLinkIcon {
  margin-left: 6px;

  color: var(--ring-icon-color, #818594);
}

.updateButton {
  padding: 0;
}

.iconAction {
  padding: 0 3px;

  &:disabled {
    visibility: hidden;
  }
}

.reordered {
  composes: font-smaller-lower;

  padding: 0 2px 0 calc(unit / 2);

  color: var(--ring-secondary-color);
}

.projectsTreeItem.disabled {
  color: var(--ring-disabled-color);
}

.withOwnPending {
  font-weight: bold;
}
