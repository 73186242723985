@value unit: var(--ring-unit);

.container {
  display: flex;

  box-sizing: border-box;
  padding: calc(unit * 1.5) calc(unit * 4);

  gap: unit;
}

.success {
  background-color: var(--ring-added-background-color);
}

.error {
  background-color: var(--ring-removed-background-color);
}

.warning {
  background-color: var(--ring-warning-background-color);
}

.info {
  background-color: var(--ring-hover-background-color);
}

.message {
  font-size: var(--ring-font-size-smaller);
}

.actions {
  display: flex;
  gap: unit;

  flex-shrink: 0;

  margin-left: auto;
}

.action {
  height: calc(unit * 2);
  padding: 0;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.icon.icon {
  height: calc(unit * 2);

  & svg {
    vertical-align: top;
  }
}

.success .icon {
  color: var(--ring-icon-success-color);
}

.error .icon {
  color: var(--ring-icon-error-color);
}

.warning .icon {
  color: var(--ring-icon-warning-color);
}

.info .icon {
  color: var(--ring-main-color);
}
