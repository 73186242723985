@value unit: var(--ring-unit);

.header {
  position: sticky;

  top: 0;

  background-color: var(--ring-content-background-color);

  &.header {
    margin-top: calc(unit * 2);
    padding-top: unit;
    padding-bottom: 0;
  }

  &:hover {
    background-color: var(--ring-content-background-color);
  }

  & > div {
    padding-bottom: unit;

    color: var(--ring-secondary-color);
    border-bottom: 1px solid var(--ring-line-color);

    font-size: var(--ring-font-size-smaller);

    line-height: var(--ring-line-height-lowest);

    &:first-child {
      padding-left: 0;
    }
  }
}
