@value unit: var(--ring-unit);

.status {
  max-height: 20px;
  padding: calc(unit / 4) unit;

  border-radius: calc(var(--ring-unit) / 2);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.standard {
  color: var(--ring-text-color);
  background-color: var(--ring-tag-background-color);
}

.error {
  color: var(--ring-error-color);
  background-color: var(--ring-removed-background-color);
}

.warning {
  color: var(--ring-warning-color);
  background-color: var(--ring-warning-background-color);
}
