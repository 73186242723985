@value unit: var(--ring-unit);

.container {
  --runner-item-width: 288px;

  padding-top: calc(unit * 2);
}

.groupItems {
  position: relative;
  left: -8px;

  display: grid;

  padding-bottom: calc(unit * 5);

  grid-template-columns: repeat(auto-fit, 288px);
  grid-auto-columns: 288px;
  grid-auto-rows: auto;
}

.modeSwitch {
  padding: calc(unit * 2) 0 calc(unit * 3);
}

.hidden {
  display: none;
}

.description,
.button {
  margin-top: 8px;

  font-size: 12px;
}

.listItemSelectLint {
  visibility: hidden;

  color: var(--ring-main-color);

  font-size: 12px;
}

.listWrap {
  margin: 0 calc(var(--tc-page-padding-inline) * -1);
}

.list {
  position: relative;

  width: 100%;

  margin-top: 2px;
}

.listItem {
  background: rgba(0, 0, 0, 0.005);

  & > td {
    padding: 6px unit 6px 0;

    vertical-align: top;
  }

  & > td:last-child {
    padding-right: calc(var(--tc-page-padding-inline) + unit);
  }

  & > td.listItemIcon {
    width: 20px;
    padding-left: var(--tc-page-padding-inline);

    line-height: 10px;
  }

  & td.title {
    width: 25%;
  }

  & td.description {
    width: 90%;
  }

  &:hover {
    cursor: pointer;

    background: var(--ring-selected-background-color);

    & .listItemSelectLint {
      visibility: visible;
    }
  }
}

.item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-bottom: 8px;
  padding: 8px 16px 12px 8px;

  border-radius: 4px;

  background: rgba(0, 0, 0, 0.005);

  &:hover {
    display: flex;

    cursor: pointer;

    background: var(--ring-selected-background-color);

    & .button {
      color: var(--ring-content-background-color);
      border-color: var(--ring-main-color);
      background-color: var(--ring-main-color);
      box-shadow: none;
    }
  }
}

.deprecated {
  color: var(--ring-secondary-color);
}

.header {
  display: flex;
}

.icon {
  height: calc(unit * 2.5);
}

:global(.ring-ui-theme-dark) .icon[data-key='nant'i] {
  filter: invert(1) brightness(1.6);
}

:global(.ring-ui-theme-dark) .icon[data-key='simpleRunner'i] {
  filter: invert(0.5);
}

:global(.ring-ui-theme-dark) .icon[data-key='gradle-runner'i] {
  filter: invert(0.5);
}

.title {
  padding-left: 8px;

  font-size: 14px;
  font-weight: bold;
}

.description {
  color: var(--ring-secondary-color);

  &.selected {
    max-width: 60%;
    margin-top: 0;
  }
}

.selectedRunner {
  display: flex;
  align-items: baseline;
}

.reselectButton {
  width: 100px;
  margin: 0;
  padding: 0;

  color: var(--ring-link-color);
  border: none;
  background: none;

  font-size: 12px;
}

.filter {
  max-width: calc(unit * 40);

  margin: calc(unit * 2) 0 unit;
}

.notFound {
  position: relative;

  padding: unit 0 calc(unit * 3);

  color: var(--ring-secondary-color);
}

.focused {
  background-color: var(--ring-selected-background-color);
}
