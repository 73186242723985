@value unit: var(--ring-unit);

.header {
  margin-bottom: calc(unit * 2);
}

.heading.heading {
  margin: 0 auto 0 0;
}

.heading.projectArchived .title {
  color: var(--ring-secondary-color);
}

.archived {
  margin-left: calc(unit / 2);

  vertical-align: text-bottom;

  font-weight: normal;
}

.star {
  margin-top: -3px;
  margin-left: 6px;

  vertical-align: 3px;
}

.top {
  display: flex;

  min-height: calc(unit * 3.5);
}

.topSequenceLoader {
  display: flex;

  gap: unit;
}

.topSequenceLoader > :empty {
  margin-left: calc(unit * -1);
}

.breadcrumbs {
  flex-grow: 1;
}

.breadcrumbsLink {
  font-size: var(--ring-font-size-smaller);
  line-height: 24px;
}

.subHeader {
  margin-top: calc(unit * 1.5);
}

.description {
  margin-top: calc(unit / 2);
}

.actions {
  display: flex;
  align-items: baseline;

  width: 100%;
  margin-top: unit;
}
