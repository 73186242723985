@value resetButton from '../../../../../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.grid {
  --column-width: minmax(0, max-content);
  --name-column: [start name-start] auto;
  --status-column: [status-start] auto;
  --requirements-column: [requirements-start] var(--column-width);

  position: relative;
  grid-template-columns: var(--name-column) var(--status-column) var(--requirements-column) [end];
}

.osIcon {
  margin-right: calc(1.5 * unit);
}

.sortIcon {
  margin-left: calc(unit / 4);

  vertical-align: middle;
}

.tableHeader {
  position: relative;

  display: contents;

  line-height: var(--ring-line-height-lowest);

  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: none;

    border-bottom: 1px solid var(--ring-line-color);

    grid-column: start / end;
    grid-row: var(--row) / span 1;
  }
}

.columnTitle {
  composes: resetButton;

  cursor: pointer;
}

.nameColumn {
  display: flex;

  grid-column-start: name-start;
  grid-row-start: var(--row);
}

.statusColumn {
  grid-column-start: status-start;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.requirementsColumn {
  grid-column-start: requirements-start;
}

.statusColumn,
.requirementsColumn {
  grid-row-start: var(--row);

  text-align: left;
}

.headerColumn {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.nameColumn,
.statusColumn,
.requirementsColumn {
  overflow: hidden;

  padding: unit calc(unit * 2) unit 0;

  &:last-child {
    padding-right: 0;
  }
}

.item,
.itemRow {
  display: contents;
}

.item::before {
  right: calc(unit * -4);
  left: calc(unit * -4);
  grid-column: start / end;
  grid-row: var(--row) / span 2;
}

.itemContent {
  display: contents;

  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: none;

    border-bottom: 1px solid var(--ring-line-color);

    grid-column: start / end;
    grid-row: var(--row) / span 2;
  }
}

.incompatibleReasonsContainer {
  padding-bottom: unit;
}

.requirementsColumn .incompatibleReasonsContainer:last-child {
  padding-bottom: 0;
}

.incompatibleReasons {
  margin: 0;
  padding: 0;

  list-style: none;

  line-height: var(--ring-line-height);
}
