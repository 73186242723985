@value unit: var(--ring-unit);

.container {
  padding: calc(unit * 1.5) 0 0 calc(unit * 2);
}

.button {
  display: block;
  overflow: hidden;

  width: 100%;

  height: auto;
  padding-right: 0;
  padding-left: 0;

  text-align: left;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
  font-weight: 600;
}

.button:hover {
  color: var(--ring-link-hover-color);
}

.chevron.chevron {
  color: var(--ring-icon-secondary-color);
}
