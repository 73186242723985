@value unit: var(--ring-unit);

.sidebar {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.searchWrapper {
  flex: 1;

  padding: 0;
}
