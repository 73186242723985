@value unit: var(--ring-unit);

.tooltip {
  padding: 6px unit;

  color: var(--ring-text-color);
}

.link {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: calc(unit * 8);

  color: var(--ring-header-link-color);

  border-radius: unit;

  font-size: 10px;
  line-height: 14px;
  padding-block: calc(unit / 2);
}

.link.link:focus-visible {
  box-shadow: 0 0 0 2px var(--ring-border-hover-color) inset;
}

.icon {
  display: block;

  margin: 2px;
  padding: unit;

  color: var(--ring-active-text-color);
  border-radius: 6px;
}

.icon.icon svg {
  vertical-align: -4px;
}

.link.link:hover {
  text-decoration: none;

  color: var(--ring-text-color);
}

.link.active {
  color: var(--ring-text-color);
}

.active .icon,
.link:hover .icon {
  background: var(--ring-tag-hover-background-color);
}
