@value unit: var(--ring-unit);

.banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  box-sizing: border-box;
  padding: 0 calc(unit * 4);

  border-bottom: 1px solid rgba(var(--ring-text-components), 0.08);

  background: var(--ring-hover-background-color);
}

.message {
  padding: calc(unit * 1.5) 0;
  padding-right: calc(unit * 2);

  font-size: var(--ring-font-size-smaller);
  font-weight: bold;
  line-height: var(--ring-line-height-lowest);
}

.learnMoreButton,
.dismissButton {
  height: 100%;
  padding: 0;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.learnMoreButton {
  margin-right: unit;
}

.dismissButton {
  color: var(--ring-secondary-color);
}
