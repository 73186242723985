@value unit: var(--ring-unit);

.alert {
  display: flex;

  align-items: flex-start;

  width: 100%;
  column-gap: unit;

  & .link {
    height: auto;
    margin-left: auto;
    padding: 0;

    font-size: var(--ring-font-size-smaller);
    line-height: var(--ring-line-height-lowest);
  }
}
