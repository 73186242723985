@value unit: var(--ring-unit);

.parameter {
  position: relative;

  display: flex;
  align-items: baseline;

  margin-bottom: unit;

  line-height: calc(unit * 3);

  &:hover .parameterActions > * {
    opacity: 1;
  }

  .parameterName,
  .parameterValue {
    display: -webkit-box;
    overflow: hidden;

    flex: 1;

    max-height: calc(unit * 12.5);

    text-overflow: ellipsis;

    line-break: anywhere;
    -webkit-line-clamp: 4;

    line-clamp: 4;

    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
  }
}

.inputContainer {
  max-width: 50%;
  margin: 0 -2px;
}

.inputs {
  display: flex;
  align-items: baseline;
  flex: 1;
  gap: unit;
}

.input {
  text-overflow: ellipsis;
}

.deleted,
.deleted .input {
  text-decoration-line: line-through;

  color: var(--ring-secondary-color);
  -webkit-text-fill-color: var(--ring-secondary-color);
}

.input:disabled {
  background-color: transparent;
}

.parameter:not(:focus-within) .input {
  border-color: transparent;
  background-color: transparent;
}

.parameter:focus-within .input {
  background-color: var(--ring-content-background-color);
}

.parameterActions {
  justify-content: end;

  width: calc(unit * 8);
}

.parameter:focus-within .parameterActions > * {
  visibility: hidden;
}
