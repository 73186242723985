@value unit: var(--ring-unit);

.actions {
  display: flex;

  width: 100%;
  column-gap: unit;

  font-size: var(--ring-font-size-smaller);
}

.remove {
  margin-left: auto;

  color: var(--ring-secondary-color);
}
