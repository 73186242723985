@value dropdownIcon from '@jetbrains/ring-ui/components/button/button.css';
@value resetButton, ellipsis from '../../../ring-globals.css';
@value special-border-color: #B5B8C8;
@value default-branch-background-color from '../BranchLabel/BranchLabel.css';
@value unit: var(--ring-unit);

.icon {
  margin-right: calc(unit / 2);

  line-height: normal;
}

.old {
  display: inline-flex;

  width: 100%;

  max-width: calc(unit * 60);
}

.branchIcon {
  margin: 0 calc(unit / 2);

  color: var(--ring-secondary-color);
}

.dropdownTrigger {
  margin: 0 calc(unit / 2);
}

.dropdownTriggerSpecial {
  position: absolute;
  top: -1px;
  right: -1px;

  border-left: 1px solid var(--ring-borders-color);
}

.oldButton {
  composes: resetButton;

  display: flex;
  align-items: baseline;

  width: 100%;

  padding: 0 4px 2px;

  cursor: pointer;
  user-select: text;

  border: 1px solid var(--ring-borders-color);

  border-radius: var(--ring-border-radius);

  background-color: var(--ring-selected-background-color);

  font-size: var(--ring-font-size);
  font-weight: normal;
  line-height: 22px;

  &:hover .dropdownTriggerSpecial {
    background-position-x: -20px;
  }

  &:active .dropdownTriggerSpecial {
    background-position-x: -40px;
  }

  &:disabled {
    cursor: inherit;

    color: var(--ring-disabled-color);

    & .branchIcon {
      color: var(--ring-icon-secondary-color);
    }

    & .dropdownTrigger {
      background-position-x: -140px;
    }

    & .dropdownTriggerSpecial {
      background-position-x: -60px;
    }
  }
}

.oldLabel {
  composes: ellipsis;

  flex-grow: 1;
}

.newLabel {
  composes: ellipsis;
}

.item {
  composes: ellipsis;
}

.minimalisticButton {
  border: none;
}

.specialButton {
  position: relative;

  overflow: hidden;

  padding-top: 1px;
  padding-right: calc(unit * 2.5);
  padding-bottom: 1px;

  border-color: var(--ring-borders-color);
  background-color: var(--ring-content-background-color);

  line-height: 19px;
}

.specialButton:active {
  background-color: var(--ring-content-background-color);
}

.specialButton[disabled] {
  border-color: var(--ring-border-disabled-color);
}

.specialButton[disabled],
.newButton[disabled],
.specialButton[disabled]:hover,
.newButton[disabled]:hover {
  --ring-button-background-color: var(--ring-disabled-background-color);

  color: var(--ring-disabled-color);
  background-color: var(--ring-disabled-background-color);
}

.defaultSelected .newLabel::selection {
  color: var(--ring-content-background-color);
  background-color: var(--tc-highlight-color);
}

.newButton {
  --ring-button-background-color: var(--ring-selected-background-color);

  display: inline-flex;
  align-items: baseline;

  user-select: text;

  &:hover,
  &:focus-visible {
    --ring-button-background-color: var(--ring-main-color);

    color: var(--ring-white-text-color);

    & .dropdownIcon {
      color: inherit;
    }

    /* stylelint-disable-next-line selector-max-specificity */
    & .newLabel::selection {
      color: var(--ring-content-background-color);
      background-color: var(--tc-highlight-color);
    }
  }
}

.newButton.newButton {
  margin: 0;
  padding-inline: unit;

  box-shadow: none;
}

.defaultSelected {
  --ring-button-background-color: default-branch-background-color;

  border-color: transparent;
  background-color: default-branch-background-color;
}

.storyTightSelect > button {
  width: 150px;
}

.tooltipWrapper {
  display: block;
  overflow: hidden;

  white-space: nowrap;
}

.label {
  max-width: calc(100% - calc(unit * 2.5 - 0.5px));
}

.badge {
  .newButton {
    width: 100%;
    padding: 0;

    color: var(--ring-text-color);
    background-color: transparent;
  }

  &:hover {
    .newButton {
      color: var(--ring-link-hover-color);
    }
  }
}
