@value unit: var(--ring-unit);

.protocolRadioGroup {
  display: flex;
  align-items: baseline;

  padding-bottom: calc(unit * 1.5);

  white-space: nowrap;

  & label {
    padding-right: calc(unit * 1.5);
  }
}

.comingSoonBadge {
  margin-left: calc(unit / 2);
}

.inputGropup {
  display: flex;

  padding-bottom: calc(unit * 1.5);
}

.formInputEmptyError {
  & div[class='ring-input-errorText'] {
    display: none;
  }
}

.inputGropupControl {
  flex-grow: 1;

  padding-left: unit;
}

.inputGropupLabel {
  flex-basis: calc(unit * 22.5);

  padding-top: calc(unit / 3);
}

.buttonFooter {
  display: flex;

  padding-top: calc(unit * 3);
}

.formCompact {
  .inputGropup {
    flex-direction: column;
  }

  .inputGropupControl {
    padding-left: 0;
  }

  .inputGropupLabel {
    flex-basis: auto;

    padding-bottom: calc(unit / 2);

    color: var(--ring-secondary-color);

    font-size: var(--ring-font-size-smaller);
  }

  .vcsAnyRepositoryAuthenticationsButtonGroup {
    display: flex;
    justify-content: space-between;

    & button {
      flex: 1;
    }
  }

  .protocolRadioGroup {
    padding-bottom: 0;
  }

  .proceedButton {
    font-size: var(--ring-font-size-smaller);
  }

  .buttonFooter {
    padding-top: calc(unit * 2);
  }
}

.errorMessage {
  display: flex;

  padding-top: calc(unit / 4);

  padding-left: calc(unit * 2);

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
}

.errorMessageText {
  word-break: break-word;

  p {
    margin: 0;
    padding: 0;
  }

  & div[class='ring-collapse-fade'] {
    display: none;
  }
}

.errorMessageIcon {
  padding-top: calc(unit / 6);
  padding-right: calc(unit / 2);
}
