@value unit: var(--ring-unit);

.container {
  display: flex;
  flex-wrap: wrap;
}

.inline {
  display: inline-flex;
  align-items: baseline;
}

.developmentMode {
  display: block;

  &.inline {
    display: inline-block;
  }

  min-width: calc(unit * 15);
  max-width: 100%;
  min-height: calc(unit * 2);

  padding: 8px;

  transition: all ease-in 0.3s;

  color: #888;

  border: 2px dashed rgba(128, 128, 128, 0.4);
  border-radius: 3px;
  background: rgba(128, 128, 128, 0.1) !important;

  & .positionName {
    display: inline;

    font-size: 8px;
  }

  &:hover {
    transition: none;

    border-color: var(--ring-icon-hover-color);

    & .positionName {
      color: var(--ring-icon-hover-color);
    }
  }

  &[id^='SAKURA_HEADER'] {
    display: inline-table;

    height: calc(unit * 4.5);

    min-height: 8px;
    margin: 0 calc(unit / 2);

    padding: 0 calc(unit / 2);

    border-radius: 0;
  }

  /* noinspection Stylelint */

  &[id='HEADER_RIGHT'] {
    padding: 0;
  }

  &[id^='SAKURA_BEFORE_CONTENT'] {
    margin: unit 0;
  }

  &[id^='SAKURA_BUILD_CHANGES'],
  &[id^='SAKURA_PROJECT_TRENDS'],
  &[id^='SAKURA_BUILD_CONFIGURATION_BUILDS'],
  &[id^='SAKURA_BUILD_CONFIGURATION_BRANCHES'],
  &[id^='SAKURA_BUILD_CONFIGURATION_CHANGE_LOG'] {
    margin-bottom: unit;
  }
}
