@value innerContainer from '@jetbrains/ring-ui/components/dialog/dialog.css';
@value unit: var(--ring-unit);

.dialog {
  overflow: hidden;

  width: calc(100% - unit * 2.5 * 2);
  height: calc(100% - unit * 2.5 * 2);
}

.overlay {
  padding: 0;

  & .innerContainer {
    width: 100%;
    height: 100%;
  }
}

.header.header {
  padding-top: calc(unit * 2.5);

  font-size: var(--ring-font-size-larger);
  line-height: 22px;
}
