.container {
  margin-bottom: calc(var(--ring-unit) * 1.5);

  font-size: var(--ring-font-size-smaller);
}

.button {
  text-decoration: underline;

  color: var(--ring-warning-color);
}

button.button:hover {
  text-decoration: none;

  color: var(--ring-warning-color);
}
