@value ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.heading {
  grid-column: 1;
  grid-row: 1;

  overflow: hidden;

  align-content: center;
}

.title {
  composes: ellipsis;
}

.title.title {
  margin: 0;
  padding: 0;
}

.titleWrapper {
  display: flex;
  align-items: baseline;
}

.numberErrors {
  margin-left: calc(unit / 2);

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
}

.warningMessage {
  display: flex;
  align-items: center;

  padding-top: calc(unit / 2);

  color: var(--ring-warning-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-font-size-smaller);
  fill: var(--ring-warning-color);

  & svg {
    width: 12px;

    margin-right: calc(unit / 2);
  }
}
