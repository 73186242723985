@value unit, secondary-color, max-content-width from '../../../globals.css';

.launcherContent {
  margin-bottom: calc(unit * 12);
  padding: calc(unit * 2) calc(unit * 4) calc(unit * 4);

  & .launcherTitle {
    margin-bottom: calc(unit * 3);
  }
}
