@value font-smaller from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.container {
  display: flex;

  flex-direction: column;

  padding-bottom: calc(unit * 2.5);

  &.empty {
    padding-top: calc(unit * 2.5);

    border-top: 1px solid var(--ring-line-color);
  }
}

.emptyListDescription {
  composes: font-smaller;

  margin: 0;
  margin-top: calc(unit - 2px);

  color: var(--ring-secondary-color);
}

.wrapper {
  width: 100%;
  min-width: calc(unit * 100);
  max-width: calc(unit * 180);
}

.title.title {
  margin: calc(unit * 4) 0 calc(unit * 2);
  padding: 0;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.runs {
  display: grid;
  grid-template-columns:
    minmax(0, max-content) minmax(0, max-content) minmax(0, max-content) minmax(0, max-content)
    minmax(0, max-content) minmax(0, max-content) minmax(0, max-content);
  grid-column-gap: calc(unit * 2);

  justify-content: space-between;
}

.columnHeader {
  padding-bottom: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.startDate {
  text-align: end;
}

.columnUnderline {
  border-bottom: 1px solid var(--ring-line-color);
  grid-column: 1/-1;
}

.pager {
  margin-top: calc(unit * 3);
}

.line {
  display: contents;
}

.durationDetails.durationDetails {
  color: var(--ring-secondary-color);
}
