@value unit: var(--ring-unit);

.activeKeys {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.heading.heading {
  margin: 0;

  font-size: 16px;
  font-weight: 700;
}

.checkbox {
  left: 4px;
}

.licenseKeysTable {
  border: 1px solid var(--ring-borders-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  & th {
    padding: calc(unit / 2) calc(unit * 1.5);

    text-align: left;

    white-space: nowrap;

    background-color: var(--ring-secondary-background-color);

    font-weight: normal;

    & .checkbox {
      top: 1px;
    }
  }

  & td {
    padding: unit calc(unit * 1.5);

    vertical-align: top;
  }

  & th,
  & td {
    border: 1px solid var(--ring-borders-color);
  }

  & td:first-child {
    padding: 0;
  }

  & td:nth-last-child(2),
  & td:nth-last-child(3) {
    white-space: nowrap;
  }

  & td:last-child {
    vertical-align: middle;
  }
}

.readOnly {
  & td:last-child {
    vertical-align: top;
    white-space: nowrap;
  }
}

.icon {
  margin-right: calc(unit / 2);
}

.show {
  margin-top: calc(unit * 1.5);
}
