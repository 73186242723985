@value font-smaller-lower from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.container {
  &:hover,
  &:focus {
    cursor: default;

    color: var(--ring-text-color);
    background-color: var(--ring-tag-background-color);
  }
}
