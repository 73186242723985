@value unit: var(--ring-unit);

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}

.label {
  composes: label from '../TimeBadge/TimeBadge.css';
}

.optimized {
  height: auto;

  color: var(--ring-text-color);
}

.reusedPopup {
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
}

.reusedItem {
  margin-top: 14px;

  line-height: var(--ring-line-height-lower);
}

.reusedItemInfo {
  margin-top: 6px;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
