@value unit: var(--ring-unit);

.formInner {
  display: grid;
  grid-template-columns: calc(unit * 22.5) auto;
  column-gap: unit;
  row-gap: calc(unit * 3);

  padding-top: calc(unit * 2);
}

.repositoryEditUrl {
  padding-bottom: unit;
  padding-left: calc(unit * 23);
}

.repoBlock {
  margin-bottom: unit;
}

.loginAlert {
  width: 100%;

  grid-column: span 2;
}

.loginAlertContent {
  display: flex;

  width: 100%;
  column-gap: unit;
}

.loginLink {
  margin-left: auto;

  text-wrap: nowrap;
}

.actions {
  padding-top: calc(unit * 3);
}
