@value unit: var(--ring-unit);

.container {
  display: flex;
  overflow: hidden;

  width: 100%;
}

.badges {
  display: flex;

  width: 100%;
  gap: calc(unit / 2);

  & > div {
    display: contents;
  }
}
