@value unit: var(--ring-unit);

.container {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.header {
  display: flex;
  gap: calc(unit * 1.5);

  justify-content: space-between;
}

.heading {
  font-weight: 600;
}

.arrows {
  display: flex;
  gap: unit;
}

.arrow {
  display: flex;
  align-items: center;

  width: calc(unit * 2);
  height: calc(unit * 2);
  padding: 0;
}

.icon {
  color: #8496ad;
}

.disabled {
  .icon {
    color: var(--ring-icon-disabled-color);
  }
}

.chart {
  position: relative;

  display: flex;

  height: 140px;

  padding-top: unit;
}

.axes {
  display: flex;
  flex-direction: row;
  gap: calc(unit * 0.75);

  width: 100%;
}

.numberAxis {
  position: relative;

  display: flex;
  flex-grow: 1;
  gap: calc(unit * 1.5);

  min-width: 240px;
  height: 100%;
}

.timeAxis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 124px;
}

.value {
  min-width: calc(unit * 2);

  text-align: center;

  color: var(--ring-disabled-color);
}

.chartBarContainer {
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: end;

  padding-top: unit;
  gap: unit;
}

.tooltipContainer {
  display: flex;
  align-self: center;

  height: 100%;
}

.tooltip {
  display: flex;
  align-self: end;

  height: 100%;
}

.chartBar {
  overflow: hidden;
  align-self: end;

  width: unit;
  height: 100%;

  cursor: pointer;

  border-top-left-radius: unit;
  border-top-right-radius: unit;

  background-color: var(--ring-main-color);

  .chartBarOptmized {
    background-color: var(--ring-border-hover-color);
  }

  &:hover {
    background-color: var(--ring-main-hover-color);

    .chartBarOptmized {
      background-color: rgba(var(--ring-border-hover-components), 0.6);
    }
  }
}

.canceledChartBar {
  align-self: center;

  width: unit;

  border-top-left-radius: unit;
  border-top-right-radius: unit;

  background-color: var(--ring-tag-background-color);
}

.lines {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  height: 108px;
  padding-top: unit;
}

.line {
  height: 1px;

  background-color: var(--ring-line-color);
}

.dashedLine {
  height: 1px;

  background: repeating-linear-gradient(
    90deg,
    var(--ring-line-color) 0 unit,
    #0000 0 calc(unit * 1.5)
  );
}

.row {
  display: flex;
  gap: calc(unit / 2);

  align-items: center;

  margin-top: calc(unit / 2);
}

.bullet {
  width: unit;
  height: unit;

  border-radius: calc(unit / 2);
  background-color: var(--ring-main-hover-color);

  &.optimized {
    &::after {
      display: block;

      width: unit;
      height: unit;

      content: '';

      border-radius: calc(unit / 2);
      background-color: rgba(var(--ring-border-hover-components), 0.6);
    }
  }
}
