@value unit: var(--ring-unit);

.pipelineParameterForm > div:first-child {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.pipelineParameterWrapForm {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  column-gap: unit;
}

.pipelineParameterFormInput {
  flex: 1;
}

.pipelineParameterFormHelperText {
  position: relative;

  margin-top: calc(unit / -2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.deleteButton {
  height: calc(unit * 2.5);
  margin-right: calc(unit * -1);

  line-height: calc(unit * 2.5);
}

.textArea {
  display: flex;

  white-space: pre-wrap !important;
}
