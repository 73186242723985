@value unit: var(--ring-unit);

.container {
  white-space: normal;
  word-break: break-word;
}

.filled {
  display: flex;

  padding: calc(unit * 1.5) calc(unit * 2);

  border-radius: calc(unit / 2);

  background-color: var(--ring-removed-background-color);
  gap: calc(1.5 * var(--ring-unit));
}

.icon {
  height: calc(unit * 2);

  & svg {
    vertical-align: top;
  }
}

/*
  Standrd alert styles
*/
.standard.success {
  color: var(--ring-icon-success-color);
}

.standard.error {
  color: var(--ring-icon-error-color);
}

.standard.warning {
  color: var(--ring-warning-color);
}

.standard.info {
  color: var(--ring-main-color);
}

/*
  Filled alert styles
*/
.filled.success {
  background-color: var(--ring-added-background-color);

  .icon {
    color: var(--ring-icon-success-color);
  }
}

.filled.error {
  background-color: var(--ring-removed-background-color);

  .icon {
    color: var(--ring-icon-error-color);
  }
}

.filled.warning {
  background-color: var(--ring-warning-background-color);

  .icon {
    color: var(--ring-icon-warning-color);
  }
}

.filled.info {
  background-color: var(--ring-hover-background-color);

  .icon {
    color: var(--ring-main-color);
  }
}
