@value unit: var(--ring-unit);

.row {
  flex-flow: row nowrap;

  margin: 0 calc(var(--tc-page-padding-inline) * -1) unit;

  padding: 1px var(--tc-page-padding-inline) 3px;

  &.changed {
    background-color: var(--ring-secondary-background-color);
    box-shadow: inset 3px 0 var(--ring-main-color);
  }

  &:hover,
  &.changed:hover {
    background-color: var(--ring-hover-background-color);
  }

  & > div {
    margin: 0;
  }
}

.buildComparerCol,
.buildNumberCol {
  width: calc(8 * unit);
  padding-right: unit;
  padding-left: unit;

  font-size: var(--ring-font-size-smaller);
}

.buildPath {
  position: relative;
  top: 1px;
}

.nameCol {
  flex: 1;

  min-width: 0;
  padding-right: unit;
  overflow-wrap: break-word;
}

.buildNumber {
  font-size: inherit;
  line-height: inherit;
}

.link {
  max-width: calc(100% - unit * 2);
  margin: 2px 0;

  color: var(--ring-text-color);

  line-height: 22px;
}

.caption {
  width: 100%;
  max-width: 100%;
  padding-right: calc(unit / 2);

  font-size: var(--ring-font-size, 14px);
}
