@value unit: var(--ring-unit);

.examples {
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.tag {
  display: inline-block;

  padding: 2px 4px;

  border: 1px solid var(--ring-borders-color);
  border-radius: var(--ring-border-radius);
  background: var(--ring-secondary-background-color);

  font-family: var(--ring-font-family-monospace), monospace;

  font-size: 10px;

  line-height: normal;
}

.specInputContainer {
  margin-top: calc(unit * 1.5);
}

.specInput {
  min-height: calc(unit * 12.5);

  font-family: var(--ring-font-family-monospace), monospace;
}

.help {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.icon {
  color: var(--ring-action-link-color);
}

.showExamples {
  padding: 0;
}
