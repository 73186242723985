@value unit: var(--ring-unit);

.input {
  background-color: var(--ring-content-background-color);
}

.input.multiline {
  overflow: auto;

  max-height: calc(unit * 20.5);

  white-space: pre;
  overscroll-behavior: contain;
}
