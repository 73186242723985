@value unit: var(--ring-unit);

.panel {
  display: flex;
  column-gap: unit;

  box-sizing: border-box;
  min-height: calc(unit * 5);
  padding: unit var(--tc-page-padding-inline);

  border-bottom: 1px solid var(--ring-line-color);
}

.breadcrumbs {
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;

  padding: 2px 0;
}
