@value unit: var(--ring-unit);

.dockerIntegrationForm {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.testDockerConnectionBlock {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.testDockerConnection {
  padding: 0;
}

.success {
  color: var(--ring-success-color);
}

.error {
  color: var(--ring-error-color);
}

.loaderInline {
  display: flex;

  color: var(--ring-action-link-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  & svg {
    margin-right: var(--ring-unit);
  }
}
