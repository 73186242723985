@value unit: var(--ring-unit);
@value duration: 300ms;
@value timing-function: cubic-bezier(0.23, 1, 0.32, 1);
@value loaderBackground from '@jetbrains/ring-ui/components/button/button.css';

.toggle {
  --tc-toggle-link-text-width: 0;
  --toggle-background-color: var(--ring-tag-background-color);
  --switcher-background-color: var(--ring-white-text-color); /* stays white in both themes */
  --switcher-color: var(--ring-icon-color);
  --label-color: var(--ring-text-color);
  --toggle-hover-background: var(--ring-border-hover-color);

  padding: 2px unit 2px 2px;

  border-radius: calc(unit * 3.5);
  background-color: var(--toggle-background-color);
  box-shadow: none;

  line-height: 24px;
}

.animated {
  transition: background timing-function duration;
}

.toggle .loaderBackground {
  border-radius: 13px;
}

.content {
  display: flex;
  align-items: center;
  gap: calc(unit/ 2);
}

.switcher {
  --tc-toggle-link-switcher-offset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(unit * 3);
  height: calc(unit * 3);

  .animated & {
    transition: transform timing-function duration;
    transform: translateX(var(--tc-toggle-link-switcher-offset));
  }

  color: var(--switcher-color);
  border-radius: calc(unit * 3.5);
  background-color: var(--switcher-background-color);

  & svg {
    vertical-align: sub;
  }
}

.label {
  .animated & {
    transition: transform timing-function duration;
  }

  color: var(--label-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.checked {
  --toggle-background-color: var(--ring-main-color);
  --switcher-color: var(--ring-main-color);
  --label-color: var(--ring-white-text-color);

  box-shadow: none;

  .switcher {
    --tc-toggle-link-switcher-offset: calc(var(--tc-toggle-link-text-width) + 10px);
  }

  &.animated .label {
    transform: translateX(-22px);
  }
}

.toggle.checked:not(.animated) {
  padding-inline: unit 2px;
}

.toggle:hover {
  --label-color: var(--ring-text-color);
  --switcher-color: var(--ring-main-color);

  background: var(--toggle-hover-background);
  box-shadow: none;
}

.checked:hover {
  --toggle-hover-background: var(--ring-main-hover-color);
  --label-color: var(--ring-white-text-color);
}

.disabled.disabled {
  --toggle-background-color: var(--ring-disabled-background-color);
  --switcher-color: var(--ring-icon-disabled-color);
  --label-color: var(--ring-disabled-color);

  box-shadow: none;
}

.disabled.checked {
  --toggle-background-color: var(--ring-border-selected-disabled-color);
}
