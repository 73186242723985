@value unit: var(--ring-unit);

.agentErrorState {
  grid-area: name;

  text-overflow: ellipsis;

  color: var(--ring-warning-color);
}

.icon {
  color: var(--ring-icon-warning-color);
}

.agentWrapperState {
  display: flex;

  align-items: center;

  gap: calc(unit / 2);
}

.incompatibilityReasonsJobLink {
  font-size: var(--ring-font-size);
}

.incompatibilityReasonsJobLinkIcon {
  margin-left: unit;
}
