@value unit: var(--ring-unit);

.toolbar {
  display: flex;
  align-items: center;

  height: calc(4.25 * unit);

  margin-bottom: calc(unit * 2);
}

.assignButton {
  margin-right: unit;
}

.excludedProjectsNumber {
  margin: calc(2 * unit) 0;
}

.filterButton {
  margin-right: calc(2 * unit);
}

.hideArchivedCheckbox {
  margin: 0;
}

.tableRow {
  display: flex;
  flex-direction: row;

  height: calc(3 * unit);

  margin-right: calc(var(--tc-page-padding-inline) * -1);
  margin-left: calc(var(--tc-page-padding-inline) * -1);

  line-height: calc(3 * unit);
}

.tableHeader {
  margin-bottom: unit;
  padding-bottom: unit;

  color: var(--ring-secondary-color);

  border-bottom: 1px solid var(--ring-line-color);
}

.nameHeader {
  margin-left: calc(var(--tc-page-padding-inline) - 5px);
}

.tableRow:hover,
.tableRow:focus-within {
  outline: none;
  background-color: var(--ring-hover-background-color);
}

.tableRow:hover .pathItem,
.tableRow:focus-within .pathItem {
  background-color: var(--ring-hover-background-color);
}

.tableRow:focus-within {
  box-shadow: 2px 0 var(--ring-main-color) inset;
}

.tableHeader:hover {
  background-color: transparent;
}

.expandableTableRow {
  cursor: pointer;
}

.tableCell {
  flex: 1 0;
}

.nameTableCell {
  margin-left: 5px;
}

.projectsTableCell {
  margin-right: calc(var(--tc-page-padding-inline) - unit);
  padding-left: 2px;
}

.unassignIcon {
  color: var(--ring-icon-color);
}
