@value unit: var(--ring-unit);

.section {
  position: relative;

  padding: calc(unit * 2) var(--tc-page-padding-inline);
  padding-left: calc(unit * 2.5);

  border-bottom: calc(unit / 2) solid var(--ring-secondary-background-color);

  &:last-child {
    margin-bottom: 0;
  }
}

.collapsibleRegion {
  position: relative;

  margin-top: unit;

  & *:last-child {
    margin-bottom: 0;
  }
}

.tooltip {
  width: 252px;
  padding: unit calc(unit * 0.75);
}
