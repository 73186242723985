.disconnectedIcon {
  color: var(--ring-icon-error-color);
}

.activity {
  margin-left: calc(var(--ring-unit) / 2);

  color: var(--ring-icon-secondary-color);
}

.activityPopup {
  max-width: 300px;

  padding: var(--ring-unit) calc(2 * var(--ring-unit));

  text-align: left;
}
