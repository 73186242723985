@value unit: var(--ring-unit);

.container {
  padding-top: calc(unit * 4);

  text-align: center;

  color: var(--ring-icon-secondary-color);
}

.icon {
  padding-bottom: unit;

  & > svg {
    width: 40px;
    height: 40px;
  }
}
