@value unit: var(--ring-unit);

.icon {
  margin-right: 6px;

  color: var(--ring-icon-secondary-color);

  line-height: normal;
}

.iconL {
  margin-right: unit;
}

.iconL.iconL > svg {
  vertical-align: -3px;
}

.failed {
  color: var(--ring-icon-error-color);
}

.failed :global(.secondary) {
  fill: var(--ring-removed-background-color);
}

.successful {
  color: var(--ring-icon-success-color);
}

.successful :global(.secondary) {
  fill: var(--ring-added-background-color);
}

.paused :global(.tertiary) {
  fill: var(--ring-icon-secondary-color);
}

.edit :global(.secondary) {
  fill: var(--ring-main-color);
}

.edit :global(.tertiary) {
  fill: var(--ring-hover-background-color);
}

.locked :global(.secondary) {
  fill: var(--ring-line-color);
}
