.wrapper {
  flex: 1;
}

.label {
  display: inline-flex;

  & .type {
    overflow: hidden;
    flex: 1;

    text-overflow: ellipsis;
  }
}

.diff {
  margin-left: 8px;

  color: #737577;

  font-size: 12px;

  & .positive {
    color: #0c7523;
  }

  & .negative {
    color: #a90f1a;
  }
}

.progressWrapper {
  margin: -6px 0 -8px;
  padding: 8px 0;
}

.progress {
  width: 100%;
  height: 3px;

  background-color: var(--ring-line-color);
}

.bar {
  height: 100%;

  &.green {
    background-color: #7acc8b;
  }

  &.yellow {
    background-color: #eda200;
  }

  &.red {
    background-color: #a90f1a;
  }
}
