.container {
  position: relative;

  display: flex;

  flex-wrap: wrap;

  margin-bottom: calc(1.5 * var(--ring-unit));

  padding-bottom: calc(2.5 * var(--ring-unit));
  gap: calc(var(--ring-unit) / 2);

  &::after {
    position: absolute;
    right: calc(-4 * var(--ring-unit));

    bottom: 0;
    left: calc(-4 * var(--ring-unit));

    content: '';

    border-top: 1px solid var(--ring-line-color);
  }
}

.copy {
  height: var(--ring-font-size-larger);
  margin-left: calc(var(--ring-unit) / 2);
  padding: 0;

  line-height: var(--ring-line-height-lowest);
}
