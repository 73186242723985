@value unit, secondary-color from '../../../globals.css';

.tags {
  margin-top: unit;
}

.tag.tag {
  margin-right: calc(unit / 2);
  padding: 0 unit;

  background-color: var(--ring-tag-background-color);
}
