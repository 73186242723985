.animationContainer {
  display: grid;

  opacity: 0;

  grid-template-rows: 0fr;

  & > div {
    overflow: hidden;
  }
}

.in {
  grid-template-rows: 1fr;

  opacity: 1;
}
