@value font from '../../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.buildTypeStatus {
  composes: font;

  height: calc(unit * 5);

  font-size: var(--ring-font-size-smaller);
}

.queued {
  margin-top: -2px;
  margin-right: calc(unit * 2);
}

.queuedAnchor {
  color: var(--ring-secondary-color);

  line-height: normal;
}

.flash {
  transition: none;

  color: var(--tc-highlight-color);
}

.queuedPopup {
  max-height: 45vh;
  padding: calc(unit / 2) unit calc(unit * 2);
}

.pending {
  font-size: var(--ring-font-size-smaller);
}

.pending,
.loading {
  color: var(--ring-secondary-color);
}
