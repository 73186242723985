@value unit: var(--ring-unit);

.actions {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  gap: unit;

  margin-left: auto;

  & > * {
    transition: opacity var(--ring-ease);
  }
}

.hiddenDefault > * {
  opacity: 0;

  &:focus-visible {
    opacity: 1;
  }
}

.addButton {
  opacity: 1;
}

.actionButton {
  height: auto;

  line-height: normal;
}
