.content {
  display: flex;

  height: 100%;
}

.sidebar {
  position: absolute;

  z-index: var(--ring-fixed-z-index); /* Need for correct scrolling work. See TW-60668 */

  overflow: hidden; /* Fix wrong page size in Edge */

  border-right: 1px solid var(--ring-line-color);

  background-color: var(--ring-sidebar-background-color);

  &.fixed {
    position: fixed;
  }

  &.collapsed .content {
    flex-grow: 0;
  }
}

.resizable {
  z-index: 11;
}

.resizableRightHandle {
  z-index: 20;
}
