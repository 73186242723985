@value unit: var(--ring-unit);

.selectPopup {
  --ring-font-size: 14px;
}

.selectedIcon {
  position: absolute;

  z-index: 1;

  fill: currentColor;

  top: unit;
  left: unit;
}

.selectButton {
  padding-left: calc(unit * 4);
}

.selectButtonWarning {
  box-shadow: inset 0 0 0 1px var(--ring-icon-warning-color);
}

.selectItem {
  padding-left: unit !important;
}

.selectItem svg {
  width: 14px;
  height: 14px;
}

.selectItem div:first-child > span {
  width: 13px;
}

.selectContainer {
  margin-top: calc(unit / 2 * -1);
  margin-right: calc(unit * 3);
}

.info {
  position: absolute;
  top: 6px;
  right: 0;
}

.container {
  position: relative;
}

.hardwareDocLink {
  display: block;

  margin-top: calc(unit / 2);
}

.alertInfoMessage {
  margin-top: calc(unit * 1.4);
}

.compatibleAgentDetailButton {
  text-decoration: underline;

  color: var(--ring-warning-color);
}

button.compatibleAgentDetailButton:hover {
  text-decoration: none;

  color: var(--ring-warning-color);
}
