.tab {
  display: flex;
  overflow: auto;
  justify-content: center;
}

.game {
  overflow: hidden;

  width: 100%;
  min-width: initial;

  height: 1221px;

  &.narrow {
    width: 320px;
    height: 930px;
  }
}

.tab,
.game {
  background-color: #faf8ef;
}
