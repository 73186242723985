@value font from '../../../../ring-globals.css';
@value font-smaller from '../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  margin-bottom: calc(unit * 2);
}

.heading.heading {
  margin: 0;
}

.star {
  margin-top: -3px;
  margin-left: 6px;

  vertical-align: 3px;
}

.top {
  display: flex;
}

.topSequenceLoader {
  display: flex;

  margin-left: auto;
  gap: unit;
}

.topSequenceLoader > :empty {
  margin-left: calc(unit * -1);
}

.subHeader {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.subHeaderItem {
  display: contents;
}

.description {
  margin-top: calc(unit / 2);
}

.systemProblems {
  max-width: calc(unit * 27.5);
}
