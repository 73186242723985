@value unit: var(--ring-unit);

.stepItem {
  z-index: 3;

  padding-bottom: unit;

  user-select: auto !important;
}

.stepWrapper {
  --ring-font-size: 14px;

  z-index: 3;

  background-color: var(--ring-content-background-color);

  font-size: var(--ring-font-size);
}

.header {
  position: relative;

  display: flex;
  align-items: start;
  column-gap: unit;

  line-height: 24px;
}

.handle {
  position: absolute;

  top: calc(unit * 2.5);
  left: calc(calc(unit * 2.2) * -1);

  padding: 0;

  transition: opacity var(--ring-ease);

  opacity: 0;
  color: var(--ring-icon-color);
}

.stepItem:hover .handle {
  opacity: 1;
}
