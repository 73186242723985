@value ellipsis from '../../../../ring-globals.css';
@value unit: var(--ring-unit);

.dialog {
  width: calc(
    100vw - unit * 6 * 2
  ); /* TODO: change to unit * 4 * 2 after fixing close button position */

  max-width: calc(unit * 70);
  height: calc(70vh - unit * 2 * 2);
}

.overlay {
  z-index: 30;

  padding: 0;
}

.wrapper {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  height: 100%;
}

.header {
  padding: calc(unit * 2) calc(unit * 4);

  &.contentScroled {
    border-bottom: 1px solid var(--ring-line-color);
    box-shadow: 0 1px 8px 0 rgba(0, 42, 76, 0.15);
  }
}

.content {
  display: flex;
  overflow: hidden;
  overflow-y: auto;

  height: 100%;
  padding: calc(unit * 2) calc(unit * 4) calc(unit * 4);
}

.historyList {
  width: 100%;
}

.title {
  display: flex;
  align-items: baseline;
}

.title.title {
  margin: 0;
  margin-right: auto;
  margin-bottom: unit;
  padding: 0;

  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

.buildTypePath {
  margin-bottom: calc(unit / 2);
}

.subTitle {
  composes: ellipsis;
}
