@value font-smaller-lower from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.wrapper {
  &.collapsed {
    height: 100%;
  }

  &.shadow {
    box-shadow: 0 2px 8px 0 rgba(0, 28, 54, 0.1);
  }
}
