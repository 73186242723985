@value unit: var(--ring-unit);

.outerContainer {
  box-sizing: border-box;
  padding: 0 calc(unit * 4);

  box-shadow: inset 0 -1px var(--ring-line-color);
}

.container {
  display: flex;
  align-items: baseline;
  justify-content: stretch;
}

.content {
  width: 100%;
  padding: calc(unit * 1.5) 0 calc(unit * 1.5) calc(unit / 2);
}

.groupedContent {
  border-bottom: 1px solid rgba(var(--ring-text-components), 0.08);
}

.groupedContent:last-child {
  border-bottom: none;
}

.hideButtonsSingle {
  padding: calc(unit * 1.5) 0;
}

.hideButtonsGrouped {
  padding: calc(unit * 0.75) 0;
}

.hideButtons {
  align-self: flex-start;

  white-space: nowrap;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.error {
  background-color: var(--ring-removed-background-color);
}

.warning {
  background-color: var(--ring-warning-background-color);
}

.info {
  background-color: var(--ring-hover-background-color);
}

.toggleButton {
  position: absolute;
  top: 7px;

  right: 0;

  color: var(--ring-main-color);
}

.icon {
  padding-top: unit;

  color: currentColor;
}

.error .icon {
  color: var(--ring-icon-error-color);
}

.warning .icon {
  color: var(--ring-icon-warning-color);
}

.info .icon {
  color: var(--ring-main-color);
}

.popupIcon {
  margin-right: unit;
}

.truncatedContainer {
  cursor: pointer;
}

.dialog {
  width: calc(unit * 100);
  min-width: calc(unit * 100);
  max-height: calc(unit * 70);
}

.contentPanel {
  border-bottom: 1px solid var(--ring-popup-border-color) !important;
}

.hideExplanation {
  margin-top: 0;
}

.fallbackHealthItem {
  height: calc(unit * 5);
}

.groupTitle {
  margin-left: calc(unit / 2);

  font-size: var(--ring-font-size-smaller);
  font-weight: bold;
  line-height: var(--ring-line-height-lowest);
}

.toggleGroupButton {
  flex-shrink: 0;

  margin-right: calc(unit * -2);
}

.toggleGroupChevron {
  margin-left: calc(unit / 2);

  vertical-align: -1px;
}

.groupedItems {
  padding: calc(unit / 2) 0 unit;

  border-top: 1px solid rgba(var(--ring-text-components), 0.08);
}

.grouped {
  padding-top: calc(unit * 0.75);
  padding-bottom: calc(unit * 0.75);
  padding-left: calc(unit * 2.5);
}

.inline {
  padding: 0;
}

.markdown {
  /* others health items have a margin-left of 4px for some reason */

  margin-left: calc(unit / 2);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  & h1 {
    font-size: 18px;
  }

  & h2 {
    font-size: 17px;
  }
}

.icon.icon {
  & svg {
    vertical-align: text-top;
  }
}
