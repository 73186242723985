@value unit: var(--ring-unit);

.label {
  padding-bottom: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.toggle {
  width: 100%;
  margin-bottom: unit;

  & .item {
    width: 50%;
  }
}

.input {
  margin-bottom: calc(unit * 1.5);
}
