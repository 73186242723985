@import '@jetbrains/ring-ui/components/global/variables.css';

@value light from '@jetbrains/ring-ui/components/global/variables.css';
@value dark from '@jetbrains/ring-ui/components/global/variables_dark.css';
@value ring: '@jetbrains/ring-ui/components/global/global.css';
@value unit: var(--ring-unit);

:root {
  --tc-message-background-color: rgba(17, 19, 20, 0.9);
  --tc-error-background-color: #faebec;
  --tc-error-hover-color: var(--ring-error-color);
  --tc-success-background-color: #e6f2e9;
  --tc-success-hover-background-color: #bcdcc2;
  --tc-success-progress-color: #7acc8b;
  --tc-success-text-color: var(--ring-text-color);
  --tc-classic-highlight-color: #ffc;
  --tc-changes-graph-background-color: #333;
  --tc-changes-graph-color: #bbb;
  --tc-classic-disabled-input-color: var(--ring-disabled-color);
  --tc-highlight-color: #ff008c;
  --tc-page-padding-inline: calc(unit * 3);
}

:global(.ring-ui-theme-dark),
.dark {
  --tc-error-background-color: #4d1f22;
  --tc-error-hover-color: #f8b4b8;
  --tc-success-background-color: #1f4d28;
  --tc-success-hover-background-color: #317c40;
  --tc-success-progress-color: #59a869;
  --tc-success-text-color: var(--ring-white-text-color);
  --tc-classic-highlight-color: #593d01;
  --tc-classic-disabled-input-color: rgba(var(--ring-text-components), 0.5);
  --tc-highlight-color: #e56ab6;
}

/* stylelint-disable selector-class-pattern */
:global(.settings-mode).light,
:global(.settings-mode) .light {
  --ring-link-components: 85, 69, 197; /* #5545c5 */
  --ring-link-hover-components: 62, 51, 140; /* #3e338c */
  --ring-action-link-components: 85, 69, 197; /* #5545c5 */
  --ring-main-components: 111, 92, 255; /* #6f5cff */
  --ring-button-primary-background-components: 111, 92, 255; /* #6f5cff */
  --ring-button-primary-border-components: 85, 69, 197; /* #5545c5 */
  --ring-main-hover-components: 105, 85, 247; /* #6955f7 */
  --ring-border-hover-components: 196, 189, 255; /* #c4bdff */
  --ring-border-disabled-active-components: 214, 209, 255; /* #d6d1ff */
  --ring-sidebar-background-components: 247, 246, 255; /* #f7f6ff */
  --ring-hover-background-components: 243, 243, 255; /* #f3f3ff */
  --ring-selected-background-components: 214, 209, 255; /* #d6d1ff */
}

:global(.settings-mode).dark.dark,
:global(.settings-mode) .dark.dark,
:global(.settings-mode.ring-ui-theme-dark.ring-ui-theme-dark),
:global(.settings-mode .ring-ui-theme-dark.ring-ui-theme-dark) {
  --ring-link-components: 196, 189, 255; /* #c4bdff */
  --ring-link-hover-components: 165, 153, 255; /* #a599ff */
  --ring-action-link-components: 196, 189, 255; /* #c4bdff */
  --ring-main-components: 111, 92, 255; /* #6f5cff */
  --ring-button-primary-background-components: 111, 92, 255; /* #6f5cff */
  --ring-button-primary-border-components: 196, 189, 255; /* #c4bdff */
  --ring-main-hover-components: 85, 69, 197; /* #5545c5 */
  --ring-border-hover-components: 85, 69, 197; /* #5545c5 */
  --ring-border-disabled-active-components: 42, 36, 91; /* #2a245b */
  --ring-sidebar-background-components: 43, 43, 48; /* #2b2b30 */
  --ring-hover-background-components: 26, 23, 53; /* #1a1735 */
  --ring-selected-background-components: 62, 51, 140; /* #3e338c */
}
/* stylelint-enable */

/* Metrics */
@value font from ring;
@value font-lower from ring;
@value font-smaller from ring;
@value font-smaller-lower from ring;
@value font-larger-lower from ring;
@value font-larger from ring;
@value thin-font from ring;
@value monospace-font from ring;
@value input-disabled from ring;
@value ellipsis from ring;

/* Note: footer also has top margin which isn't taken into account here */
@value footer-height from ring;

/* Media breakpoints (minimal values) */
@value breakpoint-small from ring;
@value breakpoint-middle from ring;
@value breakpoint-large from ring;

/* Media queries */
@value extra-small-screen-media from ring;
@value small-screen-media from ring;
@value middle-screen-media from ring;
@value large-screen-media from ring;
@value resetButton from ring;

/* https://a11yproject.com/posts/how-to-hide-content/ */

/* stylelint-disable-next-line selector-max-specificity */
.visuallyHidden,
.visuallyHiddenContainer:not(:hover, :global(.focusVisible), :active) .visuallyHiddenChild {
  position: absolute;

  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  width: 1px;
  height: 1px;

  white-space: nowrap; /* added line */
}

.lineClamp {
  position: relative;

  overflow: hidden;
  overflow-wrap: break-word;
}

.lineClampHasOverflow::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  padding-top: 1px;

  content: '\2060';

  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

@supports (-webkit-line-clamp: 2) {
  .lineClamp {
    display: -webkit-box;

    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
  }

  .lineClampHasOverflow::after {
    display: none;
  }
}
