@value unit: var(--ring-unit);

.anyVcsProviderVerifiedLabel {
  display: flex;
  align-items: center;

  color: var(--ring-text-secondary-color);

  font-size: var(--ring-font-size-smaller);

  & svg {
    margin-right: calc(unit / 2);

    color: var(--ring-success-color);
  }
}

.anyVcsProviderVerifiedLabelButton {
  margin-left: calc(unit / 4);
}
