.stopBuild {
  white-space: nowrap;
}

.iconOnly :global(.secondary) {
  fill: var(--ring-removed-subtle-background-color);
}

.invisible {
  display: none;
}
