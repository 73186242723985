@value unit: var(--ring-unit);

.groupDescription {
  padding: 0 var(--tc-page-padding-inline);
  padding-bottom: unit;

  font-size: var(--ring-font-size, 14px);

  line-height: 20px;
}

.helpIcon {
  margin-left: unit;
}
