/* stylelint-disable selector-max-specificity, selector-id-pattern */
@value font from '@jetbrains/ring-ui/components/global/global.css';

.app {
  composes: font;

  display: flex;
  flex: 1;
  flex-direction: column;
}
