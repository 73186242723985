@import '@jetbrains/ring-ui/components/input/input.css';

@value unit: var(--ring-unit);

.heightS {
  --ring-input-padding-block: 2px;
}

.richInput {
  padding-top: 1px;
  padding-bottom: 1px;
}

.disabled {
  & > div {
    color: var(--ring-disabled-color);
    border-color: var(--ring-border-disabled-color);
    background-color: var(--ring-disabled-background-color);

    -webkit-text-fill-color: var(--ring-disabled-color);
  }
}

.container {
  width: 100%;

  white-space: break-spaces;
  word-break: break-all;

  & div {
    pointer-events: all !important;
  }

  &.multiline {
    white-space: break-spaces;
  }

  .token {
    position: relative;

    transition:
      background-color 0.1s ease-out,
      background-position 0.5s ease-out;

    background: linear-gradient(to left, transparent 50%, rgba(0, 128, 248, 0.2) 50%) right;
    background-position: right bottom;
    background-size: 200% 100%;

    .prefix {
      color: var(--ring-disabled-color);
    }

    &:hover {
      background-color: rgba(0, 128, 248, 0.3);
    }
  }

  .ref {
    background-position: left;
  }

  .typing {
    background-color: rgba(0, 128, 248, 0.1);
  }
}
