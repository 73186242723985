.tooltip {
  max-width: calc(40 * var(--ring-unit));
}

.button {
  padding: 0;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}
