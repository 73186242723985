@value unit: var(--ring-unit);

.chart {
  position: relative;

  width: max-content;

  padding: calc(unit * 4.5) calc(unit * 1.5) calc(unit * 1.5);

  border-radius: calc(unit * 2);
  background: var(--ring-content-background-color);
}
