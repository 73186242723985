@value font from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.container {
  composes: font;

  display: flex;
  align-items: baseline;
  gap: unit;
}
