@value unit: var(--ring-unit);

.label {
  padding: 0 calc(unit / 2);

  color: var(--ring-icon-secondary-color);

  border-radius: calc(unit / 2);
  background-color: var(--ring-tag-background-color);
}

.on {
  color: var(--ring-main-color);
  background-color: var(--ring-selected-background-color);
}
