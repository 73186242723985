@value unit: var(--ring-unit);

.row {
  display: flex;
  flex-wrap: wrap;
  gap: unit;

  margin-bottom: calc(1.5 * unit);
}

.tree {
  margin-left: calc(unit * -2.25);

  & > .leftArrow {
    color: red;
  }
}

.leaf.leaf {
  display: block;

  padding-bottom: calc(unit * 0.75);
}

.node.node {
  align-items: center;

  margin: calc(unit * 0.75) 0;
}
