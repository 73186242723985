@value unit: var(--ring-unit);

.brush {
  fill: var(--ring-content-background-color);
}

.brushBorder {
  position: absolute;
  top: 0;
  bottom: 0;

  pointer-events: none;

  box-shadow: 0 0 0 1px var(--ring-border-hover-color) inset;
}

.previewContainer {
  margin: 0 calc(var(--tc-page-padding-inline) * -1);
  padding: 0 var(--tc-page-padding-inline);

  cursor: pointer;

  background-color: var(--ring-secondary-background-color);

  box-shadow:
    0 1px 0 0 var(--ring-line-color) inset,
    0 -1px 0 0 var(--ring-line-color) inset;
}

.preview {
  position: relative;
}
