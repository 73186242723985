@value unit: var(--ring-unit);

.pipelineSecretForm {
  display: flex;
  flex-direction: column;
}

.pipelineSecretFormHelperText {
  position: relative;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: 20px;
}
