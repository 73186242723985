@value unit: var(--ring-unit);

.badge.badge {
  min-width: calc(unit * 36);

  padding-bottom: unit;
}

.label {
  color: var(--ring-secondary-color);
}

.right {
  text-align: right;
}

.left {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.highlighted {
  color: var(--ring-main-color);

  font-weight: 600;
}

.icon {
  margin-right: calc(unit / 2);

  color: var(--ring-icon-color);
}

.skeleton {
  height: calc(unit * 2);

  margin-top: unit;

  border-radius: unit;
  background-color: var(--ring-tag-background-color);
}

.inQueue {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.running {
  display: flex;
  gap: 4px;
}

.queueContainer {
  flex-shrink: 0;

  min-width: 60px;
}

.runningContainer {
  overflow: hidden;

  width: 100%;
}

.labels {
  display: flex;
  gap: calc(unit * 0.75);

  overflow: hidden;
  justify-content: space-between;
}

.row {
  display: flex;
  gap: calc(unit * 0.75);

  width: max-content;
}

.optimization {
  display: flex;
  justify-content: end;

  color: var(--ring-main-color);

  &:hover {
    color: var(--ring-link-hover-color);

    .arrow {
      color: var(--ring-link-hover-color);
    }
  }
}

.optimizationsButton {
  height: calc(unit * 2);
  padding: 0;

  font-weight: 600;

  line-height: var(--ring-line-height-lowest);
}

.timeBarContainer {
  margin: calc(unit - 2px) 0;
}

.optimizedEnter {
  transform: translateX(100%);

  opacity: 0;
}

.optimizedEnterActive {
  transition: transform 300ms cubic-bezier(0.36, 1.33, 0.64, 1);
  transform: translateX(0%);

  opacity: 1;
}

.timeBadgePopup.timeBadgePopup {
  max-width: max-content;
}

.queueAnchor {
  position: relative;

  width: 100%;
  min-width: calc(unit * 7.5);

  text-align: left;

  &::after {
    position: absolute;
    top: 0;
    left: calc(unit * -1.5);

    width: calc(100% + calc(unit * 2));
    height: calc(100% + unit);

    content: '';
  }
}
