@value unit: var(--ring-unit);

.container {
  margin-bottom: calc(unit / 2);
}

.embedded {
  max-width: calc(unit * 30);
  padding: calc(unit * 1.5) calc(unit * 2);

  border-bottom: 1px solid var(--ring-line-color);
}

.icon {
  margin-right: unit;
}
