@value unit: var(--ring-unit);

.content {
  display: flex;
  flex-direction: column;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: unit;

  padding-bottom: calc(unit * 1.5);
}

.button {
  margin-right: calc(unit * 1.5);
  padding: 0;

  white-space: nowrap;
}

.emptyCompatible {
  color: var(--ring-warning-color);
}

.footer {
  display: flex;
  align-items: center;
}

.bold {
  font-weight: 600;
}

.numberInput {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
}

.label {
  margin-bottom: calc(var(--ring-unit) / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
