@value unit: var(--ring-unit);

.badge.badge {
  overflow: hidden;
  flex: 1 1 auto;

  min-width: 100px;
}

.branchLabel.branchLabel {
  margin-bottom: calc(unit / 2);
}

.branch {
  display: inline-flex;

  box-sizing: initial;
  max-width: 100%;
  height: calc(unit * 4.5);
  margin: 0 calc(unit * -2);
  padding: 0 calc(unit * 2);

  line-height: 24px;
}

.branchName {
  width: max-content;
  max-width: calc(100% + 1px);
}

.text {
  display: grid;
  grid-template-columns: minmax(0, min-content) minmax(auto, max-content);

  &::after {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
