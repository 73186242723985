@value unit: var(--ring-unit);

.input {
  --ring-input-padding-end: calc(var(--ring-input-padding-inline) + var(--ring-input-icon-offset));
}

.eyeButton {
  position: absolute;
  top: calc(var(--ring-input-padding-block) + 3px);
  right: var(--ring-input-padding-inline);

  height: auto;

  padding-right: 0;

  line-height: inherit;
}
