@value ellipsis, font-smaller from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  position: sticky;
  top: 0;
}

.badges {
  & > div {
    max-width: 20%;
  }
}

.button {
  margin-left: unit;
}

.timeBadge.timeBadge {
  max-width: max-content;
}
