@value resetButton from '../../../ring-globals.css';

.button {
  composes: resetButton;

  display: block;

  width: 100%;
  margin: 0;

  cursor: pointer;
}

.heading.heading {
  position: relative;

  cursor: pointer;

  font-size: inherit;
  line-height: inherit;
}

.headingWithButtonAside button,
.headingWithButtonAside a {
  position: relative;
}

.headingWithButtonAside .button {
  position: absolute;
  top: var(--absolute-child-top);
  right: 0;
  bottom: 0;
  left: 0;
}
