@value unit: var(--ring-unit);

.anchorButton {
  position: relative;
}

.dropdown {
  flex: 0 0 auto;

  font-size: var(--ring-font-size-smaller);

  line-height: 12px;
}

.loader {
  padding: 0 0 unit calc(unit * 2);
}

.item:hover > * {
  border: none;
}

.hoverItem {
  background-color: var(--ring-selected-background-color);
}

.icon,
.itemText::before {
  margin-right: calc(unit / 2);
}

.hint {
  padding-left: calc(unit * 1.5);
}

.gotIt {
  margin-top: calc(unit * 2);
}

.tooltip {
  display: inline-block;
}
