@value unit: var(--ring-unit);

.page {
  position: relative;

  display: flex;
  justify-content: center;

  width: 100%;

  & * {
    box-sizing: border-box;
  }
}

.container {
  display: flex;

  max-width: calc(unit * 150);
}

.title.title {
  margin-bottom: calc(unit * 2);

  font-weight: 700;
}

.withProjectSelector.withProjectSelector {
  margin-bottom: calc(unit / 2);
}

.projectSelector {
  display: flex;
  column-gap: calc(unit / 2);

  margin-bottom: calc(unit * 2);
}

.select {
  max-width: calc(unit * 56);
}

.createPipeline {
  width: calc(unit * 70.5);
  padding-top: calc(unit * 7.5);
}

.imageWrapper {
  margin-right: calc(unit * -7);
}

@media (width < 1280px) {
  .imageWrapper {
    display: none;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}
