.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.description {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.title.title {
  display: flex;
  align-items: baseline;

  margin-bottom: 0;
}

.icon {
  display: flex;

  margin-right: var(--ring-unit);
}

.parametersTitle.parametersTitle {
  display: flex;
  align-items: baseline;
  gap: var(--ring-unit);

  margin-top: calc(2 * var(--ring-unit));
  margin-bottom: calc(1.25 * var(--ring-unit));
}
