@value unit: var(--ring-unit);

.content {
  padding-bottom: calc(2 * unit);
}

.button {
  margin-top: calc(3 * unit);
}

.dialog {
  width: calc(100 * unit);
  min-width: calc(100 * unit);
}

.scrollableContent.scrollableContent {
  padding-top: unit;
  padding-bottom: calc(2 * unit);
}

.textButton {
  font-size: var(--ring-font-size);
}

.icon {
  margin-left: unit;
}

.info {
  margin-bottom: calc(1.5 * unit);
}

.innerContent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: calc(2 * unit);
}
