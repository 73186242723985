@value unit: var(--ring-unit);

.container {
  display: flex;
}

.inputCustom {
  flex: 1;

  padding-left: unit;
}

.selectCustom {
  width: auto;
}
