@value unit: var(--ring-unit);

.title {
  composes: title from '../../../../common/ServiceMessage/ServiceMessage.css';

  color: var(--ring-white-text-color);

  font-size: var(--ring-font-size, 14px);
}

.controls {
  composes: title from '../../../../common/ServiceMessage/ServiceMessage.css';
}

.text {
  margin-top: calc(unit / 2);
}

.description {
  margin: 0;
}

.cancel {
  margin-left: calc(unit * 2);
}
