@value unit, secondary-color, max-content-width from '../../globals.css';

.guides {
  display: grid;

  align-items: baseline;

  max-width: max-content-width;
  padding-top: calc(unit * 2);
  grid-gap: calc(unit * 4);
  grid-template-columns: repeat(3, 1fr);

  &.shortList {
    justify-items: center;

    grid-template-columns: repeat(4, 1fr);
  }
}

.startLinkContainer {
  margin-right: unit;
}

.btnPrev {
  margin-right: unit;
}

.errorDescription {
  color: var(--ring-dark-secondary-color);
}

.errorIcon {
  color: var(--ring-icon-error-color);
}

.successIcon {
  color: var(--ring-icon-success-color);
}
