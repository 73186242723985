@value unit, secondary-color, max-content-width from '../../globals.css';

.container {
  display: grid;

  max-width: max-content-width;
  grid-row-gap: calc(unit * 4);
  grid-column-gap: calc(unit * 4);
  grid-template-columns: repeat(3, 1fr);
}

.item {
  & .title {
    margin-bottom: calc(unit / 4);
    padding: 0;

    font-size: var(--ring-font-size-larger);

    font-weight: bold;
    line-height: var(--ring-line-height-lower);
  }

  & .description {
    margin-top: unit;
  }
}

.item.featured {
  display: grid;
  grid-column-start: span 3;

  grid-column-gap: calc(unit * 4);
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'video video description';

  & .title {
    margin-bottom: calc(unit / 2);

    font-size: 20px;
    font-weight: normal;
    line-height: var(--ring-line-height-taller);
  }

  & .description {
    margin-top: 0;
    grid-area: description;
  }
}

.meta {
  margin-top: unit;

  white-space: pre-wrap;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size);
}

.wrapper {
  overflow: hidden;

  width: 100%;
  aspect-ratio: 16 / 9;

  &.featured {
    grid-area: video;
  }
}

.iframe {
  width: 100%;
  height: 100%;

  border: 0;
  border-radius: var(--ring-border-radius);
}

.placeholder {
  position: relative;

  width: 100%;
  height: 100%;

  background-color: var(--ring-content-background-color);

  & .image {
    position: absolute;
    z-index: 1;

    width: 100%;
    height: 100%;
  }

  & .btnPlay {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 100%;

    transition: opacity 0.5s;

    opacity: 0;

    &:hover {
      animation: fade-in 0.5s;

      opacity: 1;
    }

    & svg {
      width: 64px;
      height: 64px;
    }
  }
}

.hidden {
  display: none;
}

.loading {
  animation: loading 0.7s linear infinite;
  animation-direction: alternate;
}

.category:not(:first-child) {
  margin-top: calc(unit * 2);
  padding-top: calc(unit * 2);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes loading {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.7;
  }
}
