@value unit: var(--ring-unit);

.compareBuildsHeader {
  margin-top: unit;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height-taller);
}

.grid {
  margin-top: 0;
  margin-bottom: calc(unit * 2);

  padding-right: 0;
  padding-left: 0;
}

.row:hover {
  background-color: var(--ring-hover-background-color);
}

.column {
  margin-top: calc(unit / 2);
  margin-bottom: calc(unit / 2);
}

.invisible {
  visibility: hidden;

  opacity: 0;
}

.buildPath {
  position: relative;
  top: 1px;
}

.buildNumber {
  font-size: inherit;
  line-height: inherit;
}

.editIcon {
  height: var(--ring-line-height-taller);
  margin-top: -1px;
  margin-left: unit;
}
