.container {
  display: inline-flex;
  align-items: center;
  align-self: stretch;

  flex-basis: auto;
  flex-shrink: 0;

  margin-left: var(--ring-unit);
}

.label {
  display: flex;
  align-items: center;
  align-self: stretch;

  white-space: pre;
}

.loader {
  margin-left: var(--ring-unit);
}
