.button {
  height: auto;
  padding: 0;

  line-height: var(--ring-line-height-lower);
}

.container {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: end;

  height: var(--ring-line-height-lower);
}

.icon {
  height: var(--ring-line-height-lowest);
}

.children {
  margin-left: calc(var(--ring-unit) * 0.5);
}
