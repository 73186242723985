@value unit: var(--ring-unit);

.button {
  box-sizing: content-box;
  width: 100%;
  padding: unit 0;

  color: var(--ring-secondary-color);

  background-color: var(--ring-secondary-background-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.queuedIcon.queuedIcon {
  margin-top: -1px;

  color: var(--ring-icon-secondary-color);
}

.flash.flash,
.flash .queuedIcon {
  transition: none;

  color: var(--tc-highlight-color);
}

.flash.flash.extraHighlight {
  background-color: var(--ring-selected-background-color);
}
