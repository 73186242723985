.row {
  margin: 0 -8px;
  padding: 8px 0;
}

.data {
  display: flex;
  align-items: baseline;

  & > * {
    flex: 1;

    padding: 0 8px;
  }
}

.path {
  display: flex;
  align-items: baseline;
  flex: 1 1 auto;
  flex-wrap: nowrap;

  padding: 2px 8px;
}

.number {
  font-size: inherit;
}

.link {
  color: var(--ring-link-color);
}

.reportButton {
  align-self: flex-end;

  width: 102px;
}

.reportButtonCell {
  text-align: right;
}
