@value ellipsis from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.wrapper {
  display: grid;
  grid-template-areas:
    'label activate'
    'details details';
  grid-template-columns: 1fr;
  row-gap: calc(unit / 4);
}

.label {
  grid-area: label;

  color: var(--ring-secondary-color);
}

.activateButton {
  grid-area: activate;
}

.detailsWrapper {
  display: flex;
  grid-area: details;
  gap: calc(unit / 2);

  align-items: center;
}

.details {
  composes: ellipsis;
}
