@value unit: var(--ring-unit);
@value margin: calc(unit / 2);

.link {
  display: inline-flex;
  align-items: baseline;

  max-width: 100%;
}

.icon {
  flex-shrink: 0;
}

.text {
  overflow: hidden;

  min-width: 0;

  padding-bottom: 1px;
}

.left {
  margin-right: margin;
}

.right {
  order: 1;

  margin-left: margin;
}

.popup {
  display: block;
}

.tooltip {
  display: block;

  max-width: calc(unit * 60);

  margin-right: -1px;
  overflow-wrap: break-word;
}

.label {
  padding-right: 1px;
}
