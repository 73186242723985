@value unit: var(--ring-unit);

.heading {
  margin: 0;
}

.summary {
  width: calc(100% + var(--tc-page-padding-inline) * 2);
  margin: 0 calc(var(--tc-page-padding-inline) * -1);
  padding: 6px var(--tc-page-padding-inline);
}

.summary:hover,
.summary:focus {
  background-color: var(--ring-hover-background-color);
}

.summary:focus {
  outline: none;
  box-shadow: 2px 0 0 0 var(--ring-main-color) inset;
}

.block {
  display: flex;
  align-items: baseline;

  height: calc(unit * 2.5);

  font-weight: normal;
}

.chevron {
  margin: 0 4px 0 -18px;

  color: var(--ring-icon-secondary-color);
}

.chart {
  display: flex;
  align-self: flex-start;
  flex-shrink: 0;

  width: calc(unit * 6 + 5px);
  height: calc(unit * 2.5);
  margin-right: unit;
}

.barContainer {
  display: flex;
  align-items: flex-end;

  width: unit;
}

.barContainer:not(:first-child) {
  margin-left: 1px;
}

.bar {
  flex-grow: 1;
}

.barFailure {
  background-color: var(--ring-icon-error-color);
}

.barFailureRunning {
  background-color: var(--tc-error-background-color);
}

.barSuccess {
  background-color: var(--tc-success-progress-color);
}

.barSuccessRunning {
  background-color: var(--tc-success-background-color);
}

.barCanceled {
  background-color: var(--ring-icon-secondary-color);
}

.barQueued {
  background-color: var(--ring-line-color);
}

.barPending {
  background-color: var(--ring-icon-color);
}

.branch,
.number {
  margin-right: unit;
}

.started {
  margin-left: auto;
  padding-left: unit;

  white-space: nowrap;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
