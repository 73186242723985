@value unit: var(--ring-unit);

.list {
  padding-bottom: unit;
}

.links {
  position: relative;

  display: flex;
  gap: calc(unit * 2.5);

  padding: calc(unit * 0.75) 0;

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: calc(var(--tc-page-padding-inline) * -1);
    bottom: 1px;
    left: calc(var(--tc-page-padding-inline) * -1);

    content: '';

    background: var(--ring-secondary-background-color);
  }
}

.linkButton {
  padding: 0;

  color: var(--ring-link-color);
}

.tabs {
  margin: unit 0 calc(unit * 1.5);
}

.grid {
  --status-column: [start status-start] max-content;
  --project-scope-column: [project-scope-start] auto;
  --changes-column: [changes-start] minmax(200px, max-content);
  --started-column: [started-start] max-content;
  --actions-column: [actions-start] max-content;

  position: relative;

  align-items: center;
  grid-column-gap: calc(unit * 2);
  grid-template-columns:
    var(--status-column) var(--project-scope-column) var(--changes-column) var(--started-column)
    var(--actions-column) [end];
}

.failureList {
  display: contents;
}

.loader {
  padding: unit 0;
}
