@value unit: var(--ring-unit);

.pipelineSecretWrapForm {
  display: flex;
  align-items: baseline;
  justify-content: space-around;

  width: 100%;

  column-gap: unit;
}

.pipelineSecretFormInput {
  flex: 1;
}

.deleteButton {
  height: calc(unit * 2.5);
  margin-right: calc(unit * -1);

  line-height: calc(unit * 2.5);
}

.textArea.textArea {
  display: flex;

  white-space: pre-wrap;
}
