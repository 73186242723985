.tooltip.tooltip {
  padding: calc(1.5 * var(--ring-unit));
  padding-bottom: calc(2 * var(--ring-unit));
}

.content {
  display: flex;

  max-width: 300px;

  white-space: normal;
  word-break: break-all;
  gap: calc(1.5 * var(--ring-unit));

  color: var(--ring-white-text-color);
}

.requirement {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) / 2);
}

.parameter {
  font-size: var(--ring-font-size-smaller);
}

.condition {
  color: var(--ring-success-color);
}
