@value unit: var(--ring-unit);

.failureLine {
  display: flex;
  align-items: baseline;

  font-size: var(--ring-font-size-smaller);
}

.icon {
  margin-right: calc(unit / 2);
}

.failure {
  color: var(--ring-error-color);
}

.logLink {
  margin-left: auto;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
