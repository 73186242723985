@value unit: var(--ring-unit);

.content {
  padding-bottom: calc(2 * unit);
}

.button {
  margin-top: calc(3 * unit);
}

.dialog {
  width: calc(80 * unit);
  min-width: calc(80 * unit);
}

.innerContent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: calc(2 * unit);
}

.input {
  margin-top: calc(2 * unit);
}

.text {
  margin-top: calc(1.5 * unit);
}

.copy {
  position: absolute;
  top: calc(2.5 * unit);
  right: calc(2 * unit);

  transition: opacity var(--ring-ease);

  opacity: 0;
}

.scriptContainer {
  position: relative;

  align-self: stretch;

  &:hover > .copy {
    opacity: 1;
  }
}

.script {
  overflow-y: auto;

  min-width: 0;
  max-height: 400px;
  margin-top: calc(1.5 * unit);
  margin-bottom: 0;
}

.copyIcon {
  width: 26px;
  height: 26px;
  padding: 0;
}
