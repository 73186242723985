.iconAction {
  display: inline-flex;

  padding: 0 3px;

  &:disabled {
    visibility: hidden;
  }
}

.starIcon {
  &.starIcon {
    transition: none;
  }

  &.hovered {
    color: var(--ring-icon-hover-color);
  }
}

.tailLink {
  display: flex;
}

.container {
  display: flex;
}

.tail {
  display: inline-flex;

  margin-right: var(--ring-unit);
  margin-left: auto;
}

.counter {
  margin-right: var(--ring-unit);
}
