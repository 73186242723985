@value unit: var(--ring-unit);

.container {
  position: relative;

  width: 100%;
  height: calc(unit / 2);

  border-radius: 6px;

  background-color: var(--ring-tag-background-color);
}

.bar {
  height: calc(unit / 2);

  transition: width 300ms cubic-bezier(0.36, 1.33, 0.64, 1);

  opacity: 1;

  border-radius: 6px;
}

.inProgress {
  animation: bar 1s linear infinite;
}

@keyframes bar {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.withSeparator {
  border-radius: 6px 0 0 6px;
}

.separator {
  position: absolute;
  top: calc(unit / -2);
  left: 100%;

  width: 1px;
  height: calc(unit * 1.5);

  transition: left 300ms cubic-bezier(0.36, 1.33, 0.64, 1);

  background-color: var(--ring-main-color);
}

.queue {
  background-color: var(--ring-borders-color);
}

.default {
  background-color: var(--ring-main-color);
}

.optimizedContainer {
  background-color: var(--ring-border-hover-color);
}
