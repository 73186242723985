@value unit: var(--ring-unit);

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

h1.disabledTitle {
  color: var(--ring-disabled-color);
}

h1.disconnectedTitle {
  color: var(--ring-error-color);
}

.title {
  margin-top: unit;
}

.goneMessage {
  margin-top: unit;
  margin-bottom: unit;
}

.status {
  margin-bottom: calc(0.5 * unit);
}

.action {
  margin-right: unit;
}

.enableWrapper {
  display: inline-block;
}

.tabs {
  composes: tabs from '../../../common/OverviewTab/OverviewTab.css';

  margin-top: calc(3 * unit);
}

.row {
  display: flex;
  flex-direction: row;
}

.rowTitle {
  width: calc(20 * unit);
}

.editLink {
  margin-left: unit;

  color: var(--ring-secondary-color);
}

.editIcon {
  color: var(--ring-icon-color);
}
