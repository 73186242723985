@value ellipsis from '../../../../ring-globals.css';
@value unit: var(--ring-unit);

.heading {
  margin-bottom: 2px;

  font-weight: bold;
}

.outerItemContainer {
  margin: 0 calc(var(--tc-page-padding-inline) * -1);
  padding: 0 var(--tc-page-padding-inline);

  line-height: 24px;

  &:hover {
    background-color: var(--ring-hover-background-color);
  }
}

.innerItemContainer {
  display: flex;
}

.changeTypeCol {
  min-width: 70px;

  text-transform: capitalize;

  font-size: var(--ring-font-size-smaller);

  &.added {
    color: var(--ring-success-color);
  }

  &.edited {
    color: var(--ring-main-color);
  }

  &.removed {
    color: var(--ring-secondary-color);
  }
}

.nameCol {
  composes: ellipsis;

  display: flex;
  flex: 1;

  margin-right: calc(unit * 2);
}

.icon {
  margin-right: unit;

  color: var(--ring-icon-secondary-color);
}

.name {
  overflow: hidden;
}

.actionsCol {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.externalViewer {
  margin-right: calc(unit * 2);
}

.actionsAnchor {
  height: unset;
  padding: 0;

  line-height: var(--ring-line-height);
}

.smallPadding {
  & .item {
    margin: 0 calc(unit * -2);
    padding: 0 calc(unit * 2);
  }

  & .outerItemContainer {
    margin: 0 calc(unit * -2);
    padding: 0 calc(unit * 2);
  }
}
