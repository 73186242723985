@value unit: var(--ring-unit);

.disabled {
  color: var(--ring-disabled-color);
}

/* Table */
.row {
  display: flex;
  align-items: baseline;

  margin: 0 calc(var(--tc-page-padding-inline) * -1) 0 calc(unit * -2);

  padding: calc(unit / 2) var(--tc-page-padding-inline) calc(unit / 2) calc(unit * 2);

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;

    background: var(--ring-hover-background-color);
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.inheritedRow {
  position: relative;

  margin-right: calc(var(--tc-page-padding-inline) * -1);
  margin-left: calc(var(--tc-page-padding-inline) * -1);
  padding-right: var(--tc-page-padding-inline);
  padding-left: var(--tc-page-padding-inline);
}

.inheritance {
  position: absolute;
  top: 6px;
  left: calc(unit * 2);
}

@value whatToSaveColWidth: calc(unit * 2);
@value artifactsColWidth: calc(unit * 3);

.col {
  flex-shrink: 0;

  padding-right: unit;

  &.icons {
    width: calc(unit * 2);
  }

  &.limits {
    width: calc(unit * 20);
  }

  &.filters {
    flex-grow: 2;

    width: calc(unit * 28);
  }

  &.statistics,
  &.dependencies,
  &.history,
  &.logs {
    width: whatToSaveColWidth;
  }

  &.artifacts {
    width: artifactsColWidth;
  }

  &:last-child {
    padding-right: 0;
  }
}

.what {
  composes: col;

  display: flex;
  align-items: baseline;

  flex-grow: 1;

  width: calc(unit * 16);
}

.artifactsDropdown {
  white-space: nowrap;
}

.modifiedIcon {
  padding-left: 2px;
}

.artifactPatternsPopup {
  max-width: calc(unit * 40);
  padding: unit calc(unit * 2) calc(unit * 2);
}

.artifactPatterns {
  margin: 0;

  white-space: pre-wrap;
  overflow-wrap: break-word;
}

/* Filters Section */
.filterItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ruleTitle {
  padding-right: calc(unit / 2);
}

.tag {
  margin-right: 0;
  margin-left: calc(unit * 1.5);
  padding: 0 unit 0 calc(unit / 2);
}

.tagWrapper {
  display: inline-block;

  margin-bottom: calc(unit / 2) !important;
}

/* Action section */
.actions {
  display: inline-flex;
  align-items: baseline;

  width: calc(unit * 14);
}

.actionButton {
  display: inline;

  margin: 0;
  margin-right: calc(unit * 2);
  padding: 0;

  text-align: left;
}

.inheritanceToggle {
  margin-left: auto;
}

/* Inheritance section */
.parent {
  display: block;
}

.parentLink {
  max-width: calc(100% - calc(unit * 3));
}

.parentPopup {
  padding: unit calc(unit * 2);
}

@keyframes blink {
  0% {
    background: var(--ring-content-background-color);
  }

  50% {
    background: var(--ring-hover-background-color);
  }

  100% {
    background: var(--ring-content-background-color);
  }
}

.blink {
  animation: blink 1s ease infinite;
}

.icon {
  margin-left: calc(unit * -0.5);
}

.warningIcon {
  composes: icon;
}

.warningIcon.warningIcon {
  color: var(--ring-icon-warning-color);
}

.warningPopup {
  padding: unit calc(unit * 2);
}
