@value unit: var(--ring-unit);

.badge {
  min-width: max-content;
}

.healthItems {
  & :global(.healthItemIndicator) {
    position: relative;

    width: 100%;
    min-width: max-content;

    margin-left: calc(unit * -0.5);
    padding: 0;

    text-align: left;

    color: var(--ring-text-color);

    border: none;
    background: none;
    box-shadow: none;

    font-size: var(--ring-font-size-smaller);

    line-height: inherit;

    &::after {
      position: absolute;
      top: calc(unit * -4.5);
      left: calc(unit * -1.5);

      width: calc(100% + calc(unit * 4));
      height: 68px;

      content: '';
    }
  }

  & :global(.info.info) {
    color: var(--ring-secondary-color);
  }

  & :global(.primary.primary) {
    padding-right: 0;
  }

  & :global(.healthItemIndicator):focus {
    outline: none;
  }

  & :global(.healthItemIndicator):hover,
  & :global(.healthItemIndicator):hover *,
  & :global(.healthItemIndicator):focus,
  & :global(.healthItemIndicator):focus * {
    fill: currentColor;
  }

  & :global(.healthItemIndicator):active,
  & :global(.healthItemIndicator):active * {
    fill: currentColor;
  }

  /* stylelint-disable-next-line selector-max-specificity */
  & :global(.healthItemIndicator):hover :global(.info) {
    color: inherit;
  }

  & :global(.refreshableInner) {
    & > span {
      display: block !important;
    }
  }
}
