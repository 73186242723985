@value unit: var(--ring-unit);

.content.content {
  padding-bottom: 0;
}

.warning {
  margin: unit 0;
}

.info {
  margin-bottom: calc(unit * 1.5);
}

.option {
  margin-bottom: unit;
}

.switch {
  margin-top: unit;
}
