@value unit: var(--ring-unit);

.vendor {
  color: var(--ring-text-secondary);

  font-weight: normal;
}

.verified {
  margin-left: calc(unit / 4);
}

.verified svg {
  vertical-align: -2px !important;
}

.right {
  float: right;
}
