@value unit: var(--ring-unit);

.container {
  padding-top: 0;
}

.label {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-font-size-larger);
}

.selectRunner {
  padding: calc(unit / 1.5) 0 0;
}
