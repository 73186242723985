@value unit: var(--ring-unit);

.container {
  position: sticky;

  z-index: 4;
  bottom: 0;

  display: flex;
  align-items: center;

  margin-top: auto;
  padding: calc(unit * 2.5);
  padding-right: var(--tc-page-padding-inline);

  color: var(--ring-secondary-color);
  background-color: var(--ring-content-background-color);

  box-shadow: var(--ring-dialog-shadow);

  font-size: var(--ring-font-size-smaller);

  column-gap: calc(unit * 1.5);
}
