@value unit: var(--ring-unit);

.list {
  position: relative;

  width: 100%;
  margin-bottom: calc(unit * 3);
}

.title {
  margin-bottom: unit;
  padding-left: calc(unit * 1.5);

  color: var(--ring-secondary-color);
}

.initialEmptyMessage {
  margin-left: calc(unit * 1.5);
}

.emptyMessage {
  margin-left: calc(unit * 1.5);

  color: var(--ring-secondary-color);
}

.errorMessage {
  margin-left: calc(unit * 1.5);

  color: var(--ring-error-color);
}

.nextPageButton {
  margin: calc(unit * 1.5);
  padding-right: 0;
  padding-left: 0;
}

.loader {
  margin-left: calc(unit * 1.5);
}
