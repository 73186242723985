@value unit: var(--ring-unit);

.header {
  padding: 0 calc(unit * 2) unit;

  background-color: var(--ring-content-background-color);

  &.pageTarget,
  &.jobDetailsPopupTarget {
    padding: 0 var(--tc-page-padding-inline) calc(unit * 1.5);
  }

  &.jobDetailsSidebarTarget,
  &.jobDebugPanelTarget {
    padding: 0 var(--tc-page-padding-inline) calc(unit * 1.5) calc(unit * 2.5);
  }

  &.jobDebugPopupTarget {
    padding: calc(unit * 2) calc(unit * 4) calc(unit * 1.5);
  }
}

.jobDetailsPopupTarget,
.jobDebugPopupTarget {
  & .controls {
    max-width: 1280px;
  }
}
