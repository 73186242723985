@value unit: var(--ring-unit);

.content {
  display: flex;

  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;

  box-sizing: border-box;
  height: 100%;
}

.tooltip {
  position: absolute;

  width: 252px;
  padding: unit calc(unit * 0.75);
}
