@value unit: var(--ring-unit);

.versionInfo {
  width: 100%;

  margin-bottom: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lowest);
}

.badges {
  display: flex;
  gap: calc(unit / 2);

  flex-wrap: wrap;

  & > div {
    height: auto;
  }
}

.value {
  display: inline-block;

  padding-bottom: calc(unit / 2);

  font-size: var(--ring-font-size);
  font-weight: 600;
  line-height: var(--ring-line-height);
}

.link {
  height: max-content;

  white-space: nowrap;

  color: var(--ring-main-color);

  font-weight: normal;
}

.learnMore {
  display: block;

  width: fit-content;
}

.buyMore {
  margin-left: unit;
}

.icon {
  margin-right: calc(unit / 2);
}

.error {
  color: var(--ring-icon-error-color);
}

.warning {
  color: var(--ring-icon-warning-color);
}

.originalLicense {
  display: block;

  margin-bottom: calc(unit / 2);

  text-decoration-line: line-through;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size);
}
