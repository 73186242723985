@value remove from '@jetbrains/ring-ui/components/tag/tag.css';
@value unit: var(--ring-unit);

.wrapper {
  display: inline-block;

  padding-left: 2px;
}

.label {
  margin-right: unit;
}

.tag {
  margin-bottom: calc(unit / 2) !important;

  &:not(:last-of-type) {
    margin-right: unit;
  }
}

.tag + .remove {
  top: 3px;
}

.showMore {
  height: auto;

  padding: 0 unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lowest);
}

.showMoreOld:hover {
  color: var(--ring-text-color);
}

.icon {
  flex: 0 0 auto;

  margin-right: calc(unit / 2);

  color: var(--ring-icon-secondary-color);
}

.add {
  padding-left: unit;

  color: var(--ring-secondary-color);
}
