@value unit: var(--ring-unit);
@value margin: calc(unit * 4);

.button {
  position: relative;

  margin-top: unit;

  padding: 0;
}
