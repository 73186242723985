@value unit: var(--ring-unit);

.heading.heading {
  margin-bottom: calc(2 * unit);
}

.licensesText {
  margin: calc(2 * unit) 0;
}

.agents {
  composes: container from '../components/AgentsGroup/AgentsGroup.css';
}

.agent {
  margin: 0 calc(var(--tc-page-padding-inline) * -1 - 2 * unit) calc(unit / 2);
  grid-column: start / end;
}
