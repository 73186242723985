@value unit: var(--ring-unit);

.item {
  padding: calc(unit * 0.75) unit !important;
}

.itemLabel {
  max-width: calc(unit * 29.5);

  cursor: default;
  white-space: normal;
}

.readonlyTooltip {
  border: none !important;
}
