@value leftShadow, rightShadow from '../TimelineStage.css';
@value unit: var(--ring-unit);

.wrapper {
  position: absolute;
  z-index: 1;
  top: 1px;

  display: block;

  margin-left: calc(unit / -4 - 14px / 2);
}

.pointIcon.pointIcon {
  color: var(--ring-icon-error-color);
  background-color: var(--ring-content-background-color);

  box-shadow: leftShadow, rightShadow;
}

.pointButton {
  height: calc(unit * 3 + 3px);
  padding: 0 calc(unit / 4) 3px;
}

.popup {
  pointer-events: none;
}

.popupContent {
  padding: 0 unit;

  white-space: nowrap;
}

.failure {
  color: var(--ring-error-color);
}
