@value unit: var(--ring-unit);

.loaderInlinePopup {
  padding: 8px;
}

.loaderInline {
  display: flex;

  color: var(--ring-action-link-color);

  font-family: var(--ring-font-family-monospace), serif;
  font-size: var(--ring-font-size-smaller);

  & svg {
    margin-right: var(--ring-unit);
  }
}

.visibleHidden {
  visibility: hidden;
}

.dropdown {
  display: inline;
}

.dropdownListItem,
.dropdownListItemLabel,
.dropdownListItemDescription {
  white-space: pre-line;

  font-family: var(--ring-font-family-monospace), serif;
  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lowest);

  line-break: normal;

  & > * {
    white-space: normal;
    line-break: normal;

    word-break: break-all;
    overflow-wrap: anywhere;
  }
}

.dropdownListItem {
  & div {
    align-self: baseline;
    flex-shrink: unset;

    min-width: 20%;
  }
}

.popup {
  max-width: 40%;
}

.suggestList {
  max-height: 50%;
}

.dropdownListItemDescription {
  width: 100%;
  padding-left: var(--ring-unit);

  text-align: right;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: 400;
  line-height: var(--ring-line-height-lowest);
}
