@value unit: var(--ring-unit);

.pipeline {
  --pipeline-background-color: var(--ring-tag-background-color);
  --pipeline-border-color: transparent;

  position: absolute;
  top: 0;
  left: 0;

  box-sizing: border-box;

  width: 100%;
  height: 100%;
  padding: calc(unit * 1.5 - 2px);

  border: 2px solid var(--pipeline-border-color);

  border-radius: calc(unit * 2);
  background: var(--pipeline-background-color);

  .label {
    display: flex;
    gap: calc(unit / 2);

    font-size: var(--ring-font-size-smaller);
    line-height: var(--ring-line-height-lowest);
  }

  &:hover {
    --pipeline-border-color: var(--ring-borders-color);

    text-decoration-line: none;
  }

  &.selected {
    --pipeline-border-color: var(--ring-main-color);
    --pipeline-background-color: var(--ring-hover-background-color);

    &:hover {
      --pipeline-border-color: var(--ring-main-color);

      color: var(--ring-link-color);
    }
  }

  &.error {
    --pipeline-background-color: rgba(var(--ring-removed-background-components), 0.5);

    color: var(--ring-error-color);

    &:hover {
      --pipeline-border-color: rgba(var(--ring-icon-error-components), 0.4);
    }
  }

  &.error.selected {
    --pipeline-border-color: var(--ring-icon-error-color);
    --pipeline-background-color: var(--ring-removed-background-color);
  }
}

.status {
  margin-left: calc(unit / 2);

  color: var(--ring-main-color);

  font-weight: 700;

  &.error {
    color: var(--ring-error-color);
  }
}
