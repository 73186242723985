@value unit: var(--ring-unit);

.actionButton {
  transition: opacity var(--ring-ease);

  opacity: 0;
}

.container {
  display: flex;
  align-items: baseline;
  gap: unit;

  padding: unit 0;

  cursor: pointer;

  &:hover .actionButton {
    opacity: 1;
  }
}

.settingsFormContainer {
  box-sizing: border-box;
  padding: calc(unit * 2) calc(unit * 4);
  padding-left: calc(unit * 2.5);
}

.errorMessage {
  padding-top: calc(unit * 0.3);

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
}

.editButtonIcon {
  margin-top: 4px;
}

.defaultBranch {
  display: flex;
  align-items: baseline;
  flex: 1;

  width: 0;

  &:hover .editButtonIcon {
    color: var(--ring-link-hover-color);
  }
}

.branch,
.vcsStorePreview {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.branchRules {
  display: flex;
  align-items: baseline;

  padding-bottom: calc(unit * 0.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.icon {
  fill: var(--ring-text-color);

  margin-top: 2px;
}

.repository {
  flex: 1;

  width: 0;
}

.repositoryName {
  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;
}

.repositoryNameMissing {
  color: var(--ring-disabled-color);
}

.deleted {
  cursor: default;
  text-decoration-line: line-through;

  color: var(--ring-secondary-color);

  .icon {
    opacity: 0.5;
  }
}
