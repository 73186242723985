@value resetButton from '../../../../../../ring-globals.css';
@value withSuccessColumn, withFailureColumn, withMutedColumn, withIgnoredColumn from '../TestScopesView.css';
@value unit: var(--ring-unit);

.tableHeader {
  position: relative;

  display: contents;
}

.tableHeader::before {
  position: absolute;
  right: 0;
  bottom: var(--absolute-child-top);
  left: 0;

  padding-bottom: unit;

  content: '';

  border-bottom: 1px solid var(--ring-line-color);

  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.columnTitle {
  composes: resetButton;

  cursor: pointer;
}

.nameColumn {
  display: flex;

  grid-column-start: name-start;
  grid-row-start: var(--row);
}

.durationColumn {
  grid-column-start: duration-start;
}

.countColumn {
  grid-column-start: count-start;
}

.successColumn {
  grid-column-start: success-start;
}

.failureColumn {
  grid-column-start: failure-start;
}

.mutedColumn {
  grid-column-start: muted-start;
}

.ignoredColumn {
  grid-column-start: ignored-start;
}

.durationColumn,
.countColumn,
.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  grid-row-start: var(--row);

  text-align: right;
}

.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  visibility: hidden;
}

.nameColumn,
.durationColumn,
.countColumn,
.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  overflow: hidden;

  padding: unit calc(unit * 2) unit 0;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);

  &:last-child {
    padding-right: 0;
  }
}

.withSuccessColumn .successColumn,
.withFailureColumn .failureColumn,
.withMutedColumn .mutedColumn,
.withIgnoredColumn .ignoredColumn {
  visibility: visible;
}

.jobDetailsPopupTarget::before {
  right: calc(var(--tc-page-padding-inline) * -1);
  left: calc(var(--tc-page-padding-inline) * -1);
}

.jobDetailsSidebarTarget::before {
  right: calc(var(--tc-page-padding-inline) * -1);
  left: calc(unit * -2.5);
}
