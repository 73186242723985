.error {
  color: var(--ring-error-color);
}

.iconButtonClose {
  position: absolute;

  top: var(--ring-unit);
  right: var(--ring-unit);
}
