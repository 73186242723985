@value unit: var(--ring-unit);

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.addSecretButton {
  height: calc(unit * 2.5);

  font-size: var(--ring-button-font-size);

  line-height: calc(unit * 2.5);
}
