@value unit: var(--ring-unit);

.outerContainer {
  --tc-tail-width: calc(unit * 10);

  overflow: hidden;

  margin: calc(unit * 2) calc(var(--tc-page-padding-inline) * -1) 0;

  padding: 0 var(--tc-page-padding-inline);
}

.invisible {
  visibility: hidden;
}

.queueInfo {
  font-size: var(--ring-font-size);
  line-height: calc(unit * 3);
}

.started {
  display: inline-block;

  margin-left: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}
