@value unit: var(--ring-unit);

.iframe {
  flex-grow: 1;

  width: 1px;

  min-width: calc(100% + var(--tc-page-padding-inline) * 2);
  margin: 0 calc(var(--tc-page-padding-inline) * -1);

  border: none;
}

.loading {
  visibility: hidden;
}
