.container {
  position: absolute;
  z-index: var(--ring-alert-z-index);
  top: 0;
  right: 0;

  overflow-y: auto;

  width: 100%;
  height: 100%;
  min-height: 100%;

  transition: transform 200ms ease-in-out;
  transform: translateX(101%);

  background-color: var(--ring-content-background-color);
}

.entering {
  transform: translateX(101%);
}

.entered {
  transform: translateX(0);
}
