@value lineClamp from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.title.title {
  margin-bottom: calc(unit / 2);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.content {
  display: flex;

  width: 100%;
  column-gap: unit;
}

.text {
  composes: lineClamp;
  -webkit-line-clamp: 4;
  line-clamp: 4;
}

.actions {
  display: flex;
  column-gap: unit;

  align-items: flex-start;

  margin-left: auto;
}

.stacktraceDialog {
  width: auto;
  min-width: calc(unit * 60);
  max-width: calc(unit * 125);

  max-height: calc(unit * 100);
}

.error {
  margin-bottom: calc(unit * 1.5);

  color: var(--ring-error-color);
}

.stacktrace {
  font-family: var(--ring-font-family-monospace), serif;
}
