@value unit: var(--ring-unit);
@value jobWidth: 192px;
@value jobHeight: 88px;

.job {
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  box-sizing: border-box;

  width: jobWidth;
  height: jobHeight;
  padding: unit;

  text-decoration-line: none;

  pointer-events: all;

  color: var(--ring-text-color);
  border: 2px solid var(--job-tile-border-color);

  border-radius: calc(unit * 1.5);

  outline-color: var(--ring-border-hover-color);

  background-color: var(--job-tile-background-color);

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height-lower);
  row-gap: calc(unit / 4);

  &:hover {
    text-decoration-line: none;

    color: var(--ring-text-color);
  }

  &.selected.selected {
    --job-tile-border-color: var(--job-tile-selected-border-color);
  }
}

.name {
  overflow: hidden;

  width: 100%;

  white-space: nowrap;

  text-overflow: ellipsis;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.top {
  display: flex;
  align-items: baseline;

  width: 100%;

  font-weight: bold;
}

.topLeft {
  display: flex;

  padding-right: calc(unit / 2);
}

.topRight {
  display: flex;
  align-items: baseline;
  gap: unit;

  margin-left: auto;
}

.status {
  display: -webkit-box;
  overflow: hidden;

  margin-top: auto;

  text-overflow: ellipsis;
  word-break: break-word;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top,
.status {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.default {
  --job-tile-border-color: var(--ring-borders-color);
}

.running {
  --job-tile-border-color: var(--ring-border-hover-color);
}

.default,
.running {
  --job-tile-background-color: var(--ring-content-background-color);
  --job-tile-selected-border-color: var(--ring-main-color);

  &.hovered,
  &:hover {
    --job-tile-background-color: var(--ring-hover-background-color);
    --job-tile-border-color: var(--ring-border-hover-color);
  }

  &.selected {
    --job-tile-background-color: var(--ring-hover-background-color);
  }
}

.success {
  --job-tile-background-color: rgba(var(--ring-added-background-components), 0.5);
  --job-tile-border-color: rgba(var(--ring-icon-success-components), 0.4);
  --job-tile-selected-border-color: var(--ring-icon-success-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--ring-added-background-color);
  }
}

.warning {
  --job-tile-background-color: rgba(var(--ring-warning-background-components), 0.5);
  --job-tile-border-color: rgba(var(--ring-icon-warning-components), 0.4);
  --job-tile-selected-border-color: var(--ring-icon-warning-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--ring-warning-background-color);
  }

  & .status {
    color: var(--ring-warning-color);
  }
}

.error {
  --job-tile-background-color: rgba(var(--ring-removed-background-components), 0.5);
  --job-tile-border-color: rgba(var(--ring-icon-error-components), 0.4);
  --job-tile-selected-border-color: var(--ring-icon-error-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--ring-removed-background-color);
  }
}

.canceled {
  --job-tile-border-color: rgba(var(--ring-borders-components), 0.4);
  --job-tile-background-color: var(--ring-content-background-color);
  --job-tile-selected-border-color: var(--ring-borders-color);

  color: var(--ring-secondary-color);

  &.hovered,
  &.selected,
  &:hover {
    --job-tile-background-color: var(--ring-tag-background-color);

    color: var(--ring-secondary-color);
  }
}

.selected {
  & .name {
    font-weight: bold;
  }
}

.deleted {
  &,
  &:hover {
    color: var(--ring-disabled-color);
  }

  & .name {
    text-decoration-line: line-through;
  }
}
