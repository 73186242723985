.approvalDialogContent {
  overflow-x: hidden;
}

.approvalDialogContent div {
  padding-top: 0;
}

.approvalDialogContent th {
  text-align: left;

  font-weight: normal;
}

.ringDialogContent {
  padding-top: 0;
}

.mainReason {
  text-align: left;

  font-size: var(--ring-font-size);
}

.buildChainReasons {
  margin-top: 7px;

  font-size: var(--ring-font-size);
}

.buildChainReasons p {
  margin-top: 0;
  margin-bottom: 0;
}

.triggerButton {
  --ring-button-height: max-content;

  padding-top: 5px;
  padding-left: 0;

  text-align: left;

  color: var(--ring-text-color);
}

.triggerButton span {
  display: inline-block;

  vertical-align: top;
}

.triggerButtonText {
  display: inline-block;

  margin-top: -3px;

  white-space: normal;
  word-break: break-word;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
}

.buildsList {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 19px;
}

.buildsListEntry {
  white-space: nowrap;
}

.buildsListLink {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;

  white-space: normal;
}
