.name {
  word-break: break-word;
}

.link {
  display: inline;

  user-select: text;

  color: var(--ring-text-color);

  line-height: inherit;

  &:hover,
  &:active,
  &:focus {
    text-decoration-line: none;

    color: var(--ring-text-color);
  }

  & > * {
    border: 0 !important;
  }
}

.suite,
.package {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.suite,
.package,
.class {
  overflow: hidden;

  &:hover,
  &:active,
  &:focus {
    text-decoration-line: none;
  }
}

.newFailure .class,
.newFailure .separator,
.newFailure .name {
  font-weight: bold;
}

.fixed .class,
.fixed .separator,
.fixed .name {
  text-decoration-line: line-through;

  color: var(--ring-secondary-color);
}

.withInteractiveLinks .class {
  color: var(--ring-link-color);
}

.withInteractiveLinks .suite,
.withInteractiveLinks .package,
.withInteractiveLinks .class {
  &:hover,
  &:focus {
    color: var(--ring-link-hover-color);
  }
}
