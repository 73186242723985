@value titles from '@jetbrains/ring-ui/components/tabs/tabs.css';
@value unit: var(--ring-unit);

.tabs > .titles {
  margin: 0;

  box-shadow: unset;
}

.tabContent {
  margin: 0 calc(var(--tc-page-padding-inline) * -1);
}

.tab,
.tabContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}
