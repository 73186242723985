.ram {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ramInput {
  margin-right: var(--ring-unit);
}

.numberInput {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
}

.customRequirement {
  &&& {
    text-decoration: none;
  }
}
