@value unit: var(--ring-unit);

.container {
  position: absolute;

  top: var(--absolute-child-top);
  bottom: 0;
  left: calc(var(--tc-page-padding-inline) * -1);

  padding: unit 0 0 5px;
  grid-column-start: start;
  grid-row: var(--row) / span 3;

  line-height: 14px;
}

.wrapped {
  left: calc(var(--tc-page-padding-inline) * -1 + unit * -2);
}

.oneLine {
  top: calc(var(--absolute-child-top) - 2px);
}

.hoverableWrapper:not(:hover) .hideableCell {
  opacity: 0;
}

.checkbox:focus-visible + .hideableCell {
  opacity: 1;
}
