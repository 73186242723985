@value unit: var(--ring-unit);

.node {
  min-height: 24px;

  line-height: 24px;
}

.leaf.expanded {
  margin: 0 calc(unit * -2);
  padding: 0 calc(unit * 2);

  box-shadow:
    0 0 0 1px var(--ring-line-color),
    0 2px 8px var(--ring-popup-shadow-color);
}

.leftArrow {
  margin-right: calc(unit / 2);
  padding: 0;

  cursor: pointer;

  color: var(--ring-icon-secondary-color);
}

.leftArrow.leftArrow:global(.focusVisible) {
  box-shadow: none;
}

.label {
  display: flex;
  overflow: hidden;
  align-items: baseline;

  user-select: text;
}

.item {
  position: relative;

  margin: 0 calc(unit * -2);
  padding: 0 calc(unit * 2);

  &.interactive {
    cursor: pointer;
  }
}

.background {
  position: absolute;
  z-index: -2;
  top: -1px;
  right: 0;
  bottom: -1px;
  left: 0;

  width: 100%;

  opacity: 0;
  background-color: var(--ring-hover-background-color);
}

.node:focus {
  outline: none;
}

.item:hover .background {
  opacity: 1;
}

/* stylelint-disable selector-max-specificity */
.leftArrow:focus + .background,
.node:focus > .item .background {
  opacity: 1;
  outline: none;
  box-shadow: inset 2px 0 var(--ring-main-color);
}
/* stylelint-enable */

.nodeClickable {
  overflow: hidden;
  flex: 1;
}

.rightArrow.rightArrow {
  top: calc(unit / 2);

  /* stylelint-disable selector-max-specificity */
  .item:hover &,
  .node:focus > .item & {
    transition: none;

    opacity: 1;
  }

  .node:focus > .item & {
    transition: none;

    opacity: 1;
    color: var(--ring-main-color);
  }
  /* stylelint-enable */
}

.labelChild {
  display: contents;
}

.checkbox {
  white-space: nowrap;
}
