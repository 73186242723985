@value ellipsis, font-smaller-lower from '../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.container {
  min-width: calc(unit * 11.5);
}

.header {
  display: flex;
  align-items: baseline;

  justify-content: space-between;

  padding-bottom: unit;
}

.label {
  color: var(--ring-secondary-color);
}

.showMore {
  height: unset;
  padding: 0;

  line-height: var(--ring-line-height-lowest);
}

.error {
  display: flex;

  align-items: center;

  margin-top: -1px;

  transition: opacity var(--ring-ease);

  column-gap: calc(unit / 2);
}

.loading {
  cursor: wait;

  opacity: 0.7;

  & > * {
    pointer-events: none;
  }
}

.icon {
  width: calc(unit * 2);
  margin-left: 1px;

  vertical-align: -1px;

  color: var(--ring-icon-error-color);
}

.message {
  composes: ellipsis;
}

.tagWrapper {
  composes: tagWrapper from '../RepositoryBadge/RepositoryTag/RepositoryTag.css';
}

.tag {
  composes: tag from '../RepositoryBadge/RepositoryTag/RepositoryTag.css';
}

.tooltipWrapper {
  display: block;
  overflow: hidden;

  white-space: nowrap;
}
