@value unit: var(--ring-unit);

.statusIcon {
  margin-right: calc(unit / 2);
}

.personalQueuedIcon {
  & :global(.secondary.secondary) {
    fill: var(--ring-text-color);
  }
}
