@value unit: var(--ring-unit);
@value gradientIncline: 135deg;

.placeholder {
  width: calc(unit * 4);
  height: calc(unit * 4);

  border-radius: calc(unit / 2);
}

.green {
  background: linear-gradient(gradientIncline, #59d29f, #62b2d8);
}

.violet {
  background: linear-gradient(gradientIncline, #62abed, #a17ae0);
}

.orange {
  background: linear-gradient(gradientIncline, #e0c684, #de6969);
}

.deprecated {
  filter: opacity(0.5);
}

.icon {
  display: flex;

  height: calc(unit * 4);
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.ring-ui-theme-dark) .icon[data-key='simpleRunner'i] {
  filter: invert(0.5);
}

/* stylelint-disable-next-line selector-class-pattern */
:global(.ring-ui-theme-dark) .icon[data-key='gradle-runner'i] {
  filter: invert(0.5);
}
