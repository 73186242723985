@value unit: var(--ring-unit);

.container {
  display: flex;

  padding: 0 calc(unit * 4) unit var(--tc-page-padding-inline);

  color: var(--ring-text-color);

  background-color: var(--ring-content-background-color);

  &.jobDetailsPopupTarget,
  &.jobDetailsSidebarTarget {
    padding-bottom: calc(unit * 1.5);
    padding-left: calc(unit * 2.5);
  }
}

.buttonGroup {
  display: flex;

  min-width: 0;
}

.successIcon {
  color: var(--ring-success-color);
}

.successIcon,
.failureIcon {
  margin-right: unit;
}

.failedBuild {
  color: var(--ring-error-color);
}

.button {
  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;
}
