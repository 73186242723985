@value unit: var(--ring-unit);

.badgeContainer {
  display: flex;
  align-items: center;

  color: var(--ring-icon-color);

  &:hover,
  &.open {
    cursor: pointer;

    color: var(--ring-main-color);
    background-color: var(--ring-hover-background-color);
  }
}

.wrappedContent {
  display: flex;
  flex-direction: column;

  margin: unit;
  gap: unit;

  & > div {
    min-width: 100%;
  }
}

.hidden {
  visibility: hidden;
}
