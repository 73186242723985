@value font-smaller-lower from '../../../ring-globals.css';
@value unit: var(--ring-unit);

.sidebar {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.footer {
  height: calc(5 * unit);

  border-top: 1px solid var(--ring-line-color);

  font-size: var(--ring-font-size-smaller);
}

.projectsTree,
.arrowKeyStepper {
  height: 100%;
}

.projectsTree,
.list {
  outline: none;
}

.content {
  height: 100%;
  margin-top: calc(unit * 1.5);
}

.loader {
  padding-left: calc(unit * 3.5);

  line-height: calc(unit * 3);

  &:first-child {
    margin-top: 0;
  }
}

.loaderText {
  margin-left: 2px;
}

.placeholder {
  composes: font-smaller-lower;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  flex-grow: 1;

  margin: calc(unit * 2) 0 0 calc(unit * 4);

  color: var(--ring-secondary-color);
}

.createButton {
  margin-top: unit;
}
