@value unit: var(--ring-unit);

.container {
  display: flex;

  gap: calc(unit / 2);
}

.containerWrapper {
  padding: calc(unit * 2);
}

.runner {
  flex-basis: 0;
  flex-grow: 1;

  min-width: 0;

  & > div {
    display: block;
  }
}

.runnerIconButton {
  height: 60px;
}

.extraRunnersMenuItem {
  display: flex;

  align-items: center;
}

.extraRunnersMenuItemIcon {
  width: 16px;
  height: 18px;

  margin-right: unit;

  & > svg {
    width: 100%;
  }
}
