@value dropdownIcon from '@jetbrains/ring-ui/components/button/button.css';
@value unit: var(--ring-unit);

.vcsSelect.vcsSelect {
  display: flex;
  align-items: baseline;

  width: 100%;

  padding-right: unit;
  padding-left: unit;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
}

.vcsIcon.vcsIcon {
  margin-right: calc(unit / 2);

  color: var(--ring-text-color);
}

.vcsSelectItem {
  & div > span {
    color: var(--ring-text-color);
  }
}

.vcsSelect .dropdownIcon {
  margin-left: auto;
}
