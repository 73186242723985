@value link, icon, text, right from '../LinkWithIcon/LinkWithIcon.css';

.button {
  height: auto;

  line-height: inherit;
}

.starred {
  --ring-button-text-color: var(--ring-text-color);
}

.invisible {
  color: transparent;
}

.invisible:focus,
.wrapper:focus-within .invisible,
.beforeWrapper:focus-within + * .invisible,
.wrapper:hover .invisible,
.beforeWrapper:hover + * .invisible {
  color: inherit;
}

.hidden {
  visibility: hidden;
}
