@value unit: var(--ring-unit);

.agentBuildTypesTab {
  position: relative;
}

.search {
  position: absolute;
  top: 0;
  right: calc(-2 * unit);
}

.section {
  margin: calc(4 * unit) calc(var(--tc-page-padding-inline) * -1) 0;
  padding: 0 var(--tc-page-padding-inline);
}

.sectionHeader {
  height: calc(3 * unit);
}

.loader {
  margin-left: calc(4 * unit);
}

.assignmentAction {
  position: absolute;
  right: 0;

  color: var(--ring-link-color);
}
