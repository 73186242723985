@value unit: var(--ring-unit);

.drop {
  position: absolute;

  top: calc(var(--absolute-child-top) - 1px);
  right: calc(var(--button-offset) * -1);
  bottom: 1px;
  left: calc(var(--button-offset) * -1);

  grid-column: start / end;
  grid-row: var(--row) / span 4;
}

.fakeContent {
  position: absolute;

  top: calc(var(--absolute-child-top) - 1px);
  right: calc(var(--button-offset) * -1);
  bottom: 1px;
  left: calc(var(--button-offset) * -1);

  width: calc(100% + var(--button-offset) * 2);

  grid-column: start / end;
  grid-row: var(--row) / span 4;
}

.above {
  grid-row: var(--row);
}

.below {
  grid-row: calc(var(--row) + 4);
}

.separator {
  position: absolute;

  z-index: var(--ring-overlay-z-index);
  top: calc(var(--absolute-child-top) - 1px);

  right: calc(var(--button-offset) * -1);
  left: calc(var(--button-offset) * -1);

  height: 2px;

  pointer-events: none;

  background-color: var(--ring-main-color);

  grid-column: start / end;
}

.invisible {
  z-index: var(--ring-invisible-element-z-index);
}

.overlay {
  z-index: var(--ring-overlay-z-index);
}

.blur {
  pointer-events: none;

  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1px);
}

.dropGap {
  position: relative;
  grid-column: start / end;
}

.handle {
  position: absolute;

  top: calc(unit * 2.5);
  bottom: 0;
  left: calc(var(--tc-page-padding-inline) * -1 + unit * -2);

  height: 30px;

  padding-top: calc(unit / 2);

  grid-column-start: start;
  grid-row: var(--row) / span 3;
}

.handleIcon {
  padding: 0 calc(unit / 2);

  cursor: move;
}

.hoverableWrapper {
  position: relative;
}

.hoverableWrapper:not(:hover) .hideableCell {
  opacity: 0;
}

@keyframes blink {
  0% {
    background: transparent;
  }

  50% {
    background: var(--ring-hover-background-color);
  }

  100% {
    background: transparent;
  }
}

.blink {
  animation: blink 0.5s ease infinite;

  opacity: 0.75;
}
