@value unit: var(--ring-unit);

.errors {
  display: flex;
  align-items: center;

  color: var(--ring-icon-error-color);

  & .errorIcon {
    margin-right: calc(unit / 2);

    transform: rotate(180deg);
  }
}

.status {
  display: flex;
  column-gap: unit;

  margin-right: auto;
}
