@value unit: var(--ring-unit);

.dialog {
  width: calc(unit * 100 - unit / 4 * 100);
}

.overlay {
  padding: 0;
}

.wrapper {
  display: flex;

  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;

  height: 100%;
}

.header {
  padding: calc(unit * 4);
  padding-bottom: calc(unit * 1.5);

  & .heading {
    margin: 0;
    padding: 0;
  }
}

.content {
  flex: 1 0 auto;

  padding: 0 calc(unit * 4);
}

.promoText {
  margin-bottom: calc(unit * 3);

  & > p {
    margin: 0;
    margin-bottom: calc(unit * 1.5);
  }

  & > p:last-child {
    margin-bottom: 0;
  }
}

.listCheckmark {
  margin-right: unit;

  color: var(--ring-main-color);
}

.boldText {
  font-weight: bold;
}

.promoImage {
  width: 100%;
  height: 312px;

  background-image: url('pipelinesPromoImage.png');
  background-size: contain;
}

.footer {
  display: flex;
  justify-content: space-between;

  padding: calc(unit * 4);
  padding-top: calc(unit * 3);
}

.tryNowIcon {
  margin-right: calc(unit / 2);
}
