@value unit, secondary-color from '../../../globals.css';

.description {
  margin-bottom: unit;
}

.shareLink.shareLink {
  padding: 0;

  transition: opacity 0.3s 0.5s;

  opacity: 0;
}

.shareLink:focus {
  opacity: 1;
}

.guide {
  max-width: calc(unit * 65);

  & span ~ .description {
    margin-top: calc(unit / 2);
  }

  &:hover {
    & .shareLink {
      transition: opacity 0.3s;

      opacity: 1;
    }
  }
}

.image {
  min-width: calc(unit * 12);

  max-width: 100%;
  min-height: calc(unit * 12);
  max-height: calc(unit * 20);
}

.title {
  margin-top: calc(unit);
  margin-right: unit;
}

.top {
  display: flex;
  align-items: flex-end;
  flex-direction: row;

  margin-top: unit;

  & .disabled {
    & .title {
      cursor: text;
      user-select: text;
    }

    cursor: default;

    color: inherit;
  }
}

.progressInfo {
  margin-left: calc(unit * 1.5);

  color: secondary-color;
}

.short {
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: calc(unit * 25);

  & .bot {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }

  & .progressInfo {
    margin-bottom: unit;
    margin-left: 0;
  }
}

.reset.reset {
  margin: 0;
  padding: 0 0 0 calc(unit / 2);
}
