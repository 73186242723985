@value unit: var(--ring-unit);

.triggerActions {
  margin-top: -1px;
}

.triggerActions > * {
  transition: opacity var(--ring-ease);

  opacity: 0;
}

.trigger {
  position: relative;

  margin-bottom: calc(unit * 1.5);

  &:hover .triggerActions > * {
    opacity: 1;
  }
}

.triggerTime {
  display: flex;
  align-items: baseline;

  justify-content: space-between;
  column-gap: unit;
}

.triggerToggle span:first-child {
  flex-shrink: 0;

  margin-top: 3px;
}

.triggerDetail {
  margin-top: calc(unit / 2);

  transition: all 300ms ease-in-out;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.triggerDetailDisabled {
  opacity: 0.4;
}
