@value unit: var(--ring-unit);

.section {
  flex: 1;

  max-width: 50%;

  margin-right: calc(unit * -1.5);
  margin-bottom: 0;
  margin-left: calc(unit * -1.5);
}

.title {
  margin-bottom: calc(unit * 1.5);
  padding-left: calc(unit * 1.5);
}
