@value unit: var(--ring-unit);

.wrapper {
  display: flex;
  overflow: hidden;

  flex: 1;
  flex-direction: column;

  margin-right: calc(var(--tc-page-padding-inline) * -1);
  margin-bottom: calc(unit * -2);
  margin-left: calc(unit * -2.5);
}

.filter {
  display: flex;

  align-items: start;
  flex-direction: column;

  padding: 0 var(--tc-page-padding-inline) unit calc(unit * 2.5);

  box-shadow: inset 0 -1px 0 0 var(--ring-line-color);
}

.changesFilter {
  margin-right: calc(unit * 2);

  &:last-child {
    margin-right: 0;
  }
}

.content {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;

  padding: 0 var(--tc-page-padding-inline) calc(unit * 2) calc(unit * 2.5);
}

.emptyList {
  font-size: var(--ring-font-size-smaller);
}
