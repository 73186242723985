.item {
  line-height: 24px;
}

.item,
.itemWrapper,
.hoverableLink {
  flex-grow: 1;

  outline: none;
}

.itemWrapper:focus,
.item:focus,
.focusedItem {
  background-color: var(--ring-selected-background-color);
  box-shadow: inset 2px 0 var(--ring-main-color);
}

.hoveredItem,
.hoverableItem:hover {
  background-color: var(--ring-tag-background-color);
}

.item.activeItem,
.item.highlightedItem {
  background-color: var(--ring-selected-background-color);
}

.hoverableLink:hover {
  text-decoration-line: none;

  color: var(--ring-text-color);
}

.activeTitle {
  font-weight: bold;
}

.counter {
  margin: 0 8px;
}
